import { Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Spin, Switch, Table, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MinusOutlined, PlusOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from 'axios';
import Search from 'antd/es/input/Search';
import * as XLSX from 'xlsx';
import { exportToExcelProductPromo } from '../../Utils/Excel/ProductPromoExcel';

const { RangePicker } = DatePicker;

function FormProductPromo() {
    const token = useSelector((state) => state.user.token);
    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dataAll, setDataAll] = useState([]);
    const [priority, setPriority] = useState(false);
    //product
    const [productDel, setProductDel] = useState([]);
    const [product, setProduct] = useState([]);
    const [variant, setVariant] = useState([]);
    const [productValue, setProductValue] = useState();
    const [variantValue, setVariantValue] = useState();
    const [outlet, setOutlet] = useState([]);
    const [outletValue, setOutletValue] = useState([]);
    const [outletDelValue, setOutletDelValue] = useState([]);
    const [arrInputDate, setArrInputDate] = useState([]);
    const [arrInputTime, setArrInputTime] = useState([]);
    const [page, setPage] = useState(1);
    const [btnLoading, setBtnLoading] = useState(false);
    const [file, setFile] = useState();
    const [uploadModalVisible, setUploadModalVisible] = useState(false);
    const [downloadModalVisible, setDownloadModalVisible] = useState(false);
    const [downloadExcelModalVisible, setDownloadExcelModalVisible] = useState(false);

    const [editProduct, setEditProduct] = useState([]);
    const [addProduct, setAddProduct] = useState([]);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (text, record, index) => (page - 1) * 10 + index + 1
        },
        {
            title: 'Nama Produk',
            dataIndex: 'ProductName',
            key: "ProductName",
            render: (text, record) => {
                var productname;
                if (record.variant_name != null) {
                    productname = record.product_name + ' - ' + record.variant_name;
                } else {
                    productname = record.product_name;
                }
                return (<p>{productname}</p>);
            }
        },
        {
            title: 'Harga Normal',
            dataIndex: 'Price',
            key: "Price",
            render: (text, record, index) => {
                return (
                    <InputNumber
                        addonBefore="Rp."
                        style={{
                            width: "90%",
                        }}
                        key={(page - 1) * 10 + index}
                        onChange={(ev) => onInputTable(ev, index, 'price_normal')}
                        placeholder='Harga'
                        defaultValue={record.price_normal}
                        value={record.price_normal}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                );
                // return (<p>{currencyFormat(Number(record.price))}</p>);
            }
        },
        {
            title: 'Diskon (%)',
            dataIndex: 'discount_percent',
            key: "discount_percent",
            render: (text, record, index) => {
                return (
                    <InputNumber
                        addonBefore="%"
                        style={{
                            width: "80%",
                        }}
                        key={(page - 1) * 10 + index}
                        onChange={(ev) => onInputTable(ev, index, 'discount_percent')}
                        placeholder='Diskon (%)'
                        defaultValue={record.discount_percent}
                        value={record.discount_percent}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                );
            }
        },
        {
            title: 'Diskon 2 (%)',
            dataIndex: 'discount_percent_2',
            key: "discount_percent_2",
            render: (text, record, index) => {
                return (
                    <InputNumber
                        addonBefore="%"
                        style={{
                            width: "80%",
                        }}
                        key={(page - 1) * 10 + index}
                        onChange={(ev) => onInputTable(ev, index, 'discount_percent_2')}
                        placeholder='Diskon 2 (%)'
                        defaultValue={record.discount_percent_2}
                        value={record.discount_percent_2}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                );
            }
        },
        {
            title: 'Harga Jual',
            dataIndex: 'price_sale',
            key: "price_sale",
            render: (text, record, index) => {
                return (
                    <InputNumber
                        addonBefore="Rp."
                        style={{
                            width: "90%",
                        }}
                        key={(page - 1) * 10 + index}
                        onChange={(ev) => onInputTable(ev, index, 'price_sale')}
                        placeholder='Harga Jual'
                        defaultValue={record.price_sale}
                        value={record.price_sale}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                );
            }
        },
        // {
        //     title: 'Qty',
        //     dataIndex: 'qty',
        //     key: "qty",
        //     render: (text, record, index) => {
        //         return (
        //             <InputNumber
        //                 key={(page - 1) * 10 + index}
        //                 onChange={(ev) => onInputTable(ev, index, 'qty')}
        //                 placeholder='Qty'
        //                 defaultValue={record.qty}
        //             />
        //         );
        //     }
        // },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: "is_active",
            render: (text, record, index) => {
                return (
                    <Switch size="small" defaultChecked={record.is_active} onChange={(ev) => onInputTable(ev, index, 'is_active')} />
                );
            }
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: "Action",
            render: (text, record, index) => {
                return (
                    <Button type="text" onClick={() => deleteRow(index)}>
                        <CloseOutlined style={{ color: "red" }} size={25} />
                    </Button>
                );
            }
        },
    ];

    const deleteRow = (index) => {
        var row = [...data];
        var i = (page - 1) * 10 + index;
        var delData = row.splice(i, 1);
        setProductDel([...productDel, ...delData]);
        setData(row);
        setDataAll(row);
    }

    const save = (ev) => {
        setIsLoading(true);
        const state = location.state;
        var method = 'POST';
        var arr_times = [];
        var arr_dates = [];
        for (let i = 0; i < arrInputDate.length; i++) {
            arr_dates.push({
                date_start: arrInputDate[i].value[0],
                date_end: arrInputDate[i].value[1]
            });
        }
        for (let i = 0; i < arrInputTime.length; i++) {
            arr_times.push({
                time_start: arrInputTime[i].value[0],
                time_end: arrInputTime[i].value[1]
            });
        }
        var arr_product_pp = [];
        for (let i = 0; i < dataAll.length; i++) {
            var item = dataAll[i];
            var arr_pp = {
                //outlet

                //variant
                product_id:item.product_id,
                variant_product_id: item.variant_product_id,
                product_name: item.product_name,
                price_sale: item.price_sale,
                price_normal: item.price_normal,
                discount_percent: item.discount_percent,
                discount_percent_2: item.discount_percent_2,
                qty: item.qty,

                is_active: item.is_active,
            }

            arr_product_pp.push(arr_pp);
        }

        // var arr_time = [];
        // if (item.product_promo_time_id !== undefined) {
        //     for (let i = 0; i < item.product_promo_time.length; i++) {
        //         arr_time.push(item.product_promo_time[i].product_promo_time_id);
        //     }
        // }
        var data_pp = {
            outlet: outletValue,
            title: ev.title,
            is_priority:priority,
            product: arr_product_pp,
            time: arr_times,
            date: arr_dates,
        }

        // if (item.product_promo_time_id !== undefined) {
        //     //productpromo
        //     data_pp.product_promo_time_id = item.product_promo_time_id;
        //     data_pp.time = arr_time;
        // }
        //params
        if (state != null) {
            data_pp.product_del = productDel;
            data_pp.product_edit = editProduct;
            data_pp.product = addProduct;
            data_pp.outlet_del = outletDelValue;
            data_pp.group_product_promo_id = state.item.group_product_promo_id;
            method = "PUT";
        } else {
            method = "POST";
        }
        postApi(method, data_pp);
    }

    const postApi = (method, values) => {
        const state = location.state;
        const dataReq = {
            method: method,
            url: method === 'PUT' ? '/productpromo/' + state.item.group_product_promo_id : '/productpromo',
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            }
        };
        apiCall(dataReq).then((res) => {
            setIsLoading(false);
            if (res.status === 200) {
                navigate(-1);
            } else {
                modalError(res.message);
            }
        }).catch((e) => {
            setIsLoading(false);
            console.log(e);
        })
    }

    useEffect(() => {
        getApi("GET", '/outlet?nopage=true', 'outlet');
        getApi("GET", '/selectproduct', 'product');
        if (location.state != null) {
            setPriority(location.state.item.is_priority)
            getApi("GET", '/productpromo/' + location.state.item.group_product_promo_id, 'detail');
        } else {
            setArrInputDate([{ label: '', value: '' }]);
            setArrInputTime([{ label: '', value: '' }]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getApi = async (method, url, type) => {
        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        await apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'product':
                        var item = res.data.rows;
                        var row = [];
                        for (let i = 0; i < item.length; i++) {
                            row.push({
                                value: JSON.stringify(item[i]),
                                label: item[i].product_name,
                            });
                        }
                        setProduct(row);
                        break;
                    case 'detail':
                        setArrInputDate([]);
                        setArrInputTime([]);
                        const data = res.data;
                        var row_date = [];
                        var row_time = [];
                        var row_data = [];
                        var row_outlet = [];
                        form.setFieldValue('title', data.title);

                        // outlet
                        for (let i = 0; i < data.outlet_product_promos.length; i++) {
                            row_outlet.push(data.outlet_product_promos[i].outlet_id);
                        }

                        // produk promo
                        for (let i = 0; i < data.product_promos.length; i++) {
                            const item = data.product_promos[i];
                            var json_data = {
                                id: Number(item.variant_product_id),
                                product_id: item.product_id,
                                variant_product_id: item.variant_product_id,
                                product_code: item.product.product_code,
                                product_name: item.product_name,
                                variant_name: '',
                                qty: item.qty,
                                price_normal: item.price_normal,
                                price_sale: item.price_sale,
                                discount_percent: item.discount_percent,
                                discount_percent_2: item.discount_percent_2,
                                is_active: item.is_active
                            }
                            row_data.push(json_data);
                        }

                        // date
                        for (let i2 = 0; i2 < data.product_promo_dates.length; i2++) {
                            var dates = data.product_promo_dates[i2];
                            row_date.push({
                                label: [dayjs(dates.date_start, 'YYYY-MM-DD'), dayjs(dates.date_end, 'YYYY-MM-DD')],
                                value: [dates.date_start, dates.date_end],
                            })
                        }

                        // time
                        for (let i2 = 0; i2 < data.product_promo_times.length; i2++) {
                            var times = data.product_promo_times[i2];
                            if (times.Start !== null && times.End !== null) {
                                row_time.push({
                                    label: [dayjs(times.time_start, 'HH:mm:ss'), dayjs(times.time_end, 'HH:mm:ss')],
                                    value: [times.time_start, times.time_end],
                                })
                            }
                        }

                        // set value
                        if (row_date.length > 0) {
                            setArrInputDate(row_date);
                        } else {
                            setArrInputDate([{ label: '', value: '' }]);
                        }
                        if (row_time.length > 0) {
                            setArrInputTime(row_time);
                        } else {
                            setArrInputTime([{ label: '', value: '' }]);
                        }
                        setOutletValue(row_outlet);
                        setData(row_data);
                        setDataAll(row_data);
                        break;
                    case 'outlet':
                        var item_outlet = res.data;
                        var row2 = [];
                        for (let i = 0; i < item_outlet.length; i++) {
                            row2.push({
                                value: item_outlet[i].outlet_id,
                                label: item_outlet[i].outlet_name,
                            });
                        }
                        setOutlet(row2);
                        break;
                    default:
                        break;
                }
            } else {
                console.log(res.message);
            }
        }).catch((e) => {
            console.log(e.message);
        })
    }

    const selectData = (event, type) => {
        var json_arr = JSON.parse(event);
        var row = [];
        if (type === 'product') {
            setProductValue(JSON.stringify(json_arr));
            for (let i = 0; i < json_arr.variant_products.length; i++) {
                row.push({
                    value: JSON.stringify(json_arr.variant_products[i]),
                    label: json_arr.variant_products[i].variant_name !== null ? json_arr.variant_products[i].variant_name : json_arr.variant_products[i].SKU,
                });
            }
            setVariantValue(null);
            setVariant(row);
        } else {
            setVariantValue(JSON.stringify(json_arr));
        }
    }

    const calculatePriceSale = (price_normal, discount1, discount2) => {
        const combinedDiscount = (1 - (1 - discount1 / 100) * (1 - discount2 / 100)) * 100;
        return Math.round(price_normal * (1 - combinedDiscount / 100));
    };

    const onInputTable = (value, index, type) => {
        const newData = [...data];
        const itemIndex = (page - 1) * 10 + index;

        if (type === 'price_normal') {
            if (value !== null) {
                newData[itemIndex][type] = value;
                const discount = ((value - newData[itemIndex].price_sale) / value) * 100;
                newData[itemIndex].discount_percent = Math.round(discount);
                newData[itemIndex].price_sale = Math.round(value * (1 - newData[itemIndex].discount_percent / 100));
            } else {
                newData[itemIndex][type] = 0;
                newData[itemIndex].discount_percent = 0;
                newData[itemIndex].price_sale = 0;
            }
        } else if (type === 'discount_percent') {
            if (value !== null) {
                newData[itemIndex][type] = value;
                newData[itemIndex].price_sale = Math.round(newData[itemIndex].price_normal * (1 - value / 100));
            } else {
                newData[itemIndex][type] = 0;
                newData[itemIndex].discount_percent = 0;
                newData[itemIndex].price_sale = 0;
            }
        } else if (type === 'discount_percent_2') {
            if (value !== null) {
                newData[itemIndex][type] = value;
                const priceSale = calculatePriceSale(newData[itemIndex].price_normal, newData[itemIndex].discount_percent, value);
                newData[itemIndex].price_sale = priceSale;
            } else {
                newData[itemIndex][type] = 0;
                newData[itemIndex].price_sale = calculatePriceSale(newData[itemIndex].price_normal, newData[itemIndex].discount_percent, 0);
            }
        } else if (type === 'price_sale') {
            if (value !== null) {
                newData[itemIndex][type] = value;
                const discount = ((newData[itemIndex].price_normal - value) / newData[itemIndex].price_normal) * 100;
                newData[itemIndex].discount_percent = Math.round(discount);
            } else {
                newData[itemIndex][type] = 0;
                newData[itemIndex].discount_percent = 0;
                newData[itemIndex].price_sale = 0;
            }
        } else {
            newData[itemIndex][type] = value;
        }

        // Update dataAll with the updated item
        const updatedItem = newData[itemIndex];
        const updatedDataAll = dataAll.map(item =>
            item.variant_product_id === updatedItem.variant_product_id ? updatedItem : item
        );
        const duplicateIndex = editProduct.findIndex(item => item.variant_product_id === newData[itemIndex].variant_product_id);
        if (duplicateIndex === -1) {
            // No duplicate found, add the new entry to editProduct
            setEditProduct([...editProduct, newData[itemIndex]]);
        }
        setData(newData);
        setDataAll(updatedDataAll);
    };

    const updateArr = (arr, key, value, valueString, type) => {
        arr[key].value = valueString;
        arr[key].label = value;
    }

    const saveProduct = () => {
        if (productValue !== null) {
            var prod = JSON.parse(productValue);
            var vari = JSON.parse(variantValue);
            if (variantValue !== null) {
                var status_duplicate = false;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].product_id === prod.product_id && data[i].variant_product_id === vari.variant_product_id) {
                        status_duplicate = true;
                    }
                }

                var cp = vari.product_category_prices[0];
                var prices = cp.discount !== null ? cp.discount.price !== null ? cp.discount.price : cp.price : cp.price;
                if (status_duplicate === false) {
                    var arr_prod = {
                        id: Number(vari.variant_product_id),
                        product_id: prod.product_id,
                        variant_product_id: vari.variant_product_id,
                        product_code:prod.product_code,
                        product_name: prod.product_name,
                        variant_name: vari.variant_name,
                        qty: 1,
                        price_normal: prices,
                        price_sale: null,
                        discount_percent: null,
                        is_active: true
                    }

                    var item = arr_prod;
                    let setDatas = [].concat(data, item);
                    setData(setDatas);
                    setDataAll(setDatas);
                    setAddProduct([...addProduct, item]);
                    setProductValue(null);
                    setVariantValue(null);
                } else {
                    modalError('Produk ini sudah ditambah');
                }
            } else {
                modalError('Varian Kosong');
            }
        } else {
            modalError('Produk Kosong');
        }
    }

    const onSelectOutlet = (ev) => {
        if (location.state != null) {
            const removedOutlets = outletValue.filter(value => !ev.includes(value));
            if (removedOutlets.length > 0) {
                setOutletDelValue([...outletDelValue, ...removedOutlets]);
            }
        }
        setOutletValue(ev);
    }

    const searchData = (value) => {
        var arr = [];
        if (value === null || value === '') {
            setData(dataAll);
        } else {
            dataAll.find(item => {
                const op = new RegExp(".*" + value + ".*", 'ig');
                const hsl = item.product_name.match(op);
                if (hsl !== null) {
                    arr.push(item)
                }
                return '';
            })
            setData(arr);
        }
    }

    const handleUpload = (file) => {
        if (file) {
            setBtnLoading(true);

            const reader = new FileReader();
            reader.onload = (e) => {
                const dataArr = new Uint8Array(e.target.result);
                const workbook = XLSX.read(dataArr, { type: 'array' });

                // Ambil sheet pertama
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                // Konversi sheet menjadi JSON
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                const processedData = [];
                for (let i = 0; i < product.length; i++) {
                    const item = JSON.parse(product[i].value);
                    for (let i2 = 0; i2 < jsonData.length; i2++) {
                        const row = jsonData[i2];
                        if (row['Nama Produk'] === item.product_name) {
                            var price_sale = row['Harga Setelah Diskon'] ? Number(row['Harga Setelah Diskon']) : 0;
                            var price_normal = row['Harga Normal'] ? Number(row['Harga Normal']) : 0;
                            var disc = row['Diskon'] ? Number(row['Diskon']) : 0;
                            var disc_2 = row['Diskon 2'] ? Number(row['Diskon 2']) : 0;
                            if (price_sale === 0) {
                                price_sale = Math.round(price_normal * (1 - disc / 100));
                                if (disc_2 > 0) {
                                    price_sale = calculatePriceSale(price_normal, disc, disc_2);
                                }
                            }
                            const priceNormal = Math.round(row['Harga Normal']);
                            const priceSale = Math.round(price_sale);
                            processedData.push({
                                discount_percent: row['Diskon'],
                                discount_percent_2: row['Diskon 2'],
                                id: item.variant_products[0].variant_product_id,
                                is_active: row['Status'] === 'Aktif' ? true : false,
                                price_normal: priceNormal,
                                price_sale: priceSale,
                                product_id: item.product_id,
                                product_name: row['Nama Produk'],
                                qty: row['Qty'],
                                variant_name: item.variant_products[0].variant_name,
                                variant_product_id: item.variant_products[0].variant_product_id
                            });
                        }
                    }
                }

                const editProducts = [];
                let updatedData = [...data];

                processedData.forEach(item => {
                    const existingProductIndex = data.findIndex(existing => existing.product_name === item.product_name);

                    if (existingProductIndex !== -1) {
                        editProducts.push(item);
                        updatedData[existingProductIndex] = item;
                    } else {
                        editProduct.push(item);
                        updatedData.push(item);
                    }
                });

                setEditProduct([...editProduct, ...editProducts]);

                setData(updatedData);
                setDataAll(updatedData);

                // Tutup modal dan hentikan loading
                setUploadModalVisible(false);
                setBtnLoading(false);
            };

            reader.readAsArrayBuffer(file);

        } else {
            console.error('File belum dipilih');
        }
    };


    function downloadFile() {
        setIsLoading(true);
        axios({
            url: '/assets/TEMPLATE_DATA_PRODUK_PROMO.xlsx', // URL file di dalam proyek React Anda
            method: 'GET',
            responseType: 'blob', // Set responseType sebagai 'blob' untuk unduhan file
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'TEMPLATE_DATA_PRODUK_PROMO.xlsx');
                document.body.appendChild(link);
                link.click();
                // Hapus URL objek setelah unduhan selesai
                window.URL.revokeObjectURL(url);
                setIsLoading(false);
                setDownloadModalVisible(false)
            })
            .catch((error) => {
                console.error('Error downloading file:', error);
            });
    }
    
    function exportFileExcel() {
        exportToExcelProductPromo(data)
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="primary" onClick={form.submit} size='large' >
                    <b style={{ marginLeft: 5 }}>Simpan</b>
                </Button>
            </div>
            <Spin spinning={isLoading}>
                <Form form={form} name="form_product" layout="vertical" onFinish={save}>
                    <div style={{ marginBottom: 10 }}>
                        <Form.Item
                            name="title"
                            label="Judul"
                            rules={[
                                {
                                    required: true,
                                    message: 'Judul kosong!'
                                },
                            ]}

                        >
                            <Input />
                        </Form.Item>
                        <p>Pilih Outlet</p>
                        <Select
                            mode="multiple"
                            showSearch
                            placeholder="Pilih Outlet"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            onChange={(ev) => onSelectOutlet(ev)}
                            options={outlet}
                            value={outletValue}
                            // disabled={outletValue !== null ? true : false}
                            style={{ marginRight: 10, width: '100%' }}
                        />
                        {
                            outletValue !== null ?
                                <>
                                    <p>Pilih Produk</p>
                                    <Select
                                        showSearch
                                        placeholder="Pilih Produk"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        onChange={(ev) => selectData(ev, 'product')}
                                        options={product}
                                        value={productValue}
                                        style={{ marginRight: 10, width: 300 }}
                                    />
                                    {
                                        variant !== null &&
                                        <Select
                                            showSearch
                                            placeholder="Pilih Varian"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            onChange={(ev) => selectData(ev, 'variant')}
                                            options={variant}
                                            value={variantValue}
                                            style={{ marginRight: 10, width: 300 }}
                                        />
                                    }
                                </>
                                :
                                null
                        }
                        <Button
                            onClick={() => saveProduct()}
                            type="primary"
                            style={{ marginTop: 5 }}>
                            Tambah Produk <PlusOutlined />
                        </Button>
                    </div>
                    <hr /> 
                    <Row gutter={16} style={{ marginBottom: 20 }}>
                        <Col span={12}>
                            <Search
                                style={{ width: '100%' }}
                                placeholder="Pencarian"
                                allowClear
                                enterButton="Search"
                                size="medium"
                                onSearch={searchData}
                            />
                        </Col>
                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <div>
                                <span style={{ marginRight: 8 }}>Priority:</span>
                                <Switch
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    checked={priority}
                                    onChange={(ev) => setPriority(ev)}
                                />
                            </div>
                            <Button
                                type="primary"
                                style={{ marginLeft: 10 }}
                                onClick={() => setUploadModalVisible(true)}
                            >
                                Unggah File Excel
                            </Button>
                            <Button
                                type="default"
                                style={{ marginLeft: 10 }}
                                onClick={() => setDownloadModalVisible(true)}
                            >
                                Download Template
                            </Button>
                            <Button
                                type="default"
                                style={{ marginLeft: 10 }}
                                onClick={() => exportFileExcel(true)}
                            >
                                Export Excel
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        size="middle"
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            onChange(current) {
                                setPage(current);
                            }
                        }}
                    />

                    <div>
                        <Row>
                            <Col>
                                {
                                    arrInputDate.map((key, i) => (
                                        <Form.Item
                                            key={i}
                                            label={"Tanggal Produk Promo " + (i + 1).toString()}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Tanggal Produk Promo kosong!'
                                                },
                                            ]}
                                        >
                                            {/* <DatePicker
                                            placeholder='Pilih Tanggal'
                                            style={{ width: 300 }}
                                            picker='date'
                                            onChange={(date, dateString) => updateArr(arrInputDate, i, date, dateString, 'date')}
                                            defaultValue={key.label}
                                        /> */}
                                            <RangePicker
                                                defaultValue={key.label}
                                                format="YYYY-MM-DD"
                                                onChange={(date, dateString) => updateArr(arrInputDate, i, date, dateString, 'date')}
                                            />
                                        </Form.Item>
                                    ))
                                }
                            </Col>
                            <Col>
                                <Button onClick={() => setArrInputDate([...arrInputDate, { label: '', value: '' }])} style={{ marginLeft: 10, marginTop: 30 }} type="primary"><PlusOutlined /></Button>
                                <Button onClick={() => setArrInputDate(arrInputDate.splice(0, arrInputDate.length - 1))} style={{ marginLeft: 10, marginTop: 30 }} type="primary"><MinusOutlined /></Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {
                                    arrInputTime.map((key, i) => (
                                        <Form.Item
                                            key={i}
                                            label={"Jam Produk Promo " + (i + 1).toString()}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Jam Produk Promo kosong!'
                                                },
                                            ]}
                                        >
                                            <RangePicker
                                                style={{ width: 300 }}
                                                showTime={{
                                                    format: 'HH:mm:ss',
                                                    defaultValue: key.label
                                                }}
                                                format="HH:mm:ss"
                                                picker='time'
                                                onChange={(date, dateString) => updateArr(arrInputTime, i, date, dateString, 'time')}
                                            />
                                        </Form.Item>
                                    ))
                                }
                            </Col>
                            <Col>
                                <Button onClick={() => setArrInputTime([...arrInputTime, { label: '', value: '' }])} style={{ marginLeft: 10, marginTop: 30 }} type="primary"><PlusOutlined /></Button>
                                <Button onClick={() => setArrInputTime(arrInputTime.splice(0, arrInputTime.length - 1))} style={{ marginLeft: 10, marginTop: 30 }} type="primary"><MinusOutlined /></Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Spin>


            <Modal
                title="Download Template Excel"
                visible={downloadModalVisible}
                onCancel={() => setDownloadModalVisible(false)}
                footer={null}
            >
                <p>Apakah Anda yakin ingin mengunduh template Excel?</p>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                    <Button onClick={() => setDownloadModalVisible(false)} style={{ marginRight: '8px' }}>
                        Batal
                    </Button>
                    <Button type="primary" onClick={downloadFile}>
                        Unduh
                    </Button>
                </div>
            </Modal>

            <Modal
                title="Unggah File Excel"
                visible={uploadModalVisible}
                onCancel={() => setUploadModalVisible(false)}
                footer={null}
            >
                <Upload
                    accept=".xlsx, .xls"
                    beforeUpload={false}
                    onChange={(info) => {
                        setFile(info.file.originFileObj);
                    }}
                    showUploadList={false} // Menyembunyikan daftar file yang diunggah
                >
                    <Button icon={<UploadOutlined />}>Pilih File</Button>
                </Upload>
                {!file && (
                    <p>Silakan pilih file terlebih dahulu</p>
                )}
                {file && (
                    <p>File yang akan diunggah: {file.name}</p>
                )}
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                    <Button onClick={() => setUploadModalVisible(false)} style={{ marginRight: '8px' }}>Batal</Button>
                    <Button type="primary" disabled={btnLoading} onClick={() => handleUpload(file)} icon={<UploadOutlined />}>{btnLoading === true ? 'Loading...' : 'Unggah'}</Button>

                </div>
            </Modal>
        </div>
    );
}

export default FormProductPromo;