
import { Alert, Button, Col, Form, Input, Modal, Radio, Row, Select, Table, TreeSelect } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';
const { SHOW_PARENT } = TreeSelect;


function Employee() {
    const token = useSelector((state) => state.user.token);
    //DATA
    const [data, setData] = useState([]);
    const [userRole, setUserRole] = useState([]);
    const [outlet, setOutlet] = useState([]);

    const [outletSelectedAll, setOutletSelectedAll] = useState(true);

    const [userPOS, setUserPOS] = useState([]);

    const [statusOpenOutlet, setStatusOpenOutlet] = useState(false);

    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [form] = Form.useForm();
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Karyawan';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: true,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: "name",
            sorter: true,
            render: (id, record) => { return record.detail_user.first_name + ' ' + record.detail_user.last_name; }
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: "username",
            sorter: true,
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: "email",
            sorter: true,
        },
        {
            title: 'Posisi',
            dataIndex: 'role',
            key: "role",
            sorter: true,
            render: (id, record) => { return record.user_role.position; }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('edit', record)}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi('GET', '/employee?search=' + search + '&page=' + page.pagination.current, 'employee');
        if (userRole.length === 0) {
            getApi('GET', '/userrole?nopage=true', 'userrole');
        }
        if (outlet.length === 0) {
            getApi('GET', '/outlet?nopage=true', 'outlet');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (method, url, type) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                if (type === 'employee') {
                    setData(res.data.data);
                    setPage({
                        ...page,
                        pagination: {
                            ...page.pagination,
                            total: res.data.total_data
                        }
                    });
                    setLoading(false);
                } else if (type === 'userrole') {
                    var u = [];
                    var roleId = [];
                    for (let i = 0; i < res.data.length; i++) {
                        res.data[i].user_role_menus.forEach(item => {
                            if (Number(item.menu_id) === 2) {
                                roleId.push(res.data[i].user_role_id);
                            }
                        });
                        u.push({
                            value: res.data[i].user_role_id,
                            label: res.data[i].position
                        });
                    }
                    if (roleId.length > 0) {
                        setUserPOS([...userPOS, ...roleId]);
                    }
                    setUserRole(u);
                } else if (type === 'outlet') {
                    var o = [];
                    for (let i = 0; i < res.data.length; i++) {
                        o.push({
                            key: res.data[i].outlet_name,
                            value: res.data[i].outlet_id,
                            title: res.data[i].outlet_name
                        });
                    }
                    setOutlet(o);
                }
            } else {
                setLoading(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const postApi = (method, url, values) => {
        setLoading(true);
        setIsAlert(false);

        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setdataModal(null);
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', '/employee/' + v.user_id, null),
        });
    }

    const openModal = async (type, data) => {
        if (type === 'edit') {
            checkPOS(data.user_role_id);
            var o = [];
            if(data.user_outlets.length > 0){
                setOutletSelectedAll(false)
            }else{
                setOutletSelectedAll(true)
            }
            for (let i = 0; i < data.user_outlets.length; i++) {
                var outlets = data.user_outlets[i];
                o.push(outlets.outlet_id);
            }
            setdataModal(data)
            form.setFieldsValue({
                email: data.email,
                phone: data.phone,
                username: data.username,
                user_role_id: data.user_role_id,
                nik: data.detail_user.nik,
                first_name: data.detail_user.first_name,
                last_name: data.detail_user.last_name,
                gender: data.detail_user.gender,
                address: data.detail_user.address,
                city: data.detail_user.city,
                province: data.detail_user.province,
                country: data.detail_user.country,
                outlet_id: o
            })
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    }

    const onFinish = (ev) => {
        ev.statusOutletSelected = outletSelectedAll;
        if (dataModal != null) {
            ev.user_id = dataModal.user_id;
            postApi('PUT', '/employee', ev);
        } else {
            postApi('POST', '/employee', ev);
        }
    }

    const closeModal = () => {
        setdataModal(null);
        setIsModal(false);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const checkPOS = (ev) => {
        var statusOpen = false;
        userPOS.forEach(item => {
            if (item === ev) {
                statusOpen = true;
            }
        });
        setStatusOpenOutlet(statusOpen);
    }
    return (
        <div>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Karyawan</h3>
            <Row>
                <Col span={8}>
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah Karyawan</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal title="Data Karyawan" open={isModal} onOk={form.submit} onCancel={() => closeModal()} okText="Simpan" >
                <Form form={form} name="form_employee" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="user_role_id"
                        label="Pilih Posisi Hak Akses"
                        rules={[
                            {
                                required: true,
                                message: 'Posisi Hak Akses kosong!'
                            },
                        ]}
                    >
                        <Select
                            onChange={(e) => checkPOS(e)}
                            options={userRole}
                        />
                    </Form.Item>
                    <Form.Item label="Pilih Status Outlet">
                        <Radio.Group onChange={(e) => setOutletSelectedAll(e.target.value)} value={outletSelectedAll}>
                            <Radio value={true}>Pilih Semua Outlet</Radio>
                            <Radio value={false}>Pilih Outlet</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {
                        statusOpenOutlet === true && outletSelectedAll === false ?
                        <Form.Item
                            name="outlet_id"
                            label="Pilih Outlet"
                            rules={[
                                {
                                    required: outletSelectedAll,
                                    message: 'Outlet kosong!'
                                },
                            ]}
                        >
                            <TreeSelect
                                placement='bottomLeft'
                                treeData={outlet}
                                showSearch
                                treeCheckable={true}
                                showCheckedStrategy={SHOW_PARENT}
                                allowClear
                                dropdownMatchSelectWidth={false}
                                filterTreeNode={(input, node) =>
                                    node.title.toLowerCase().includes(input.toLowerCase())
                                }
                                titleRender={(node) => node.title}
                            />
                        </Form.Item>
                        :
                        null
                    }
                    <Row>
                        <Col span={12} style={{ paddingRight: 10 }}>
                            <Form.Item
                                name="first_name"
                                label="Nama Depan"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Nama Depan kosong!'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 10 }}>
                            <Form.Item
                                name="last_name"
                                label="Nama Belakang"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Nama Belakang kosong!'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        name="username"
                        label="Username"
                        rules={[
                            {
                                required: true,
                                message: 'Username kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: false,
                                message: 'Password kosong!'
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                            {
                                required: true,
                                message: 'E-mail kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    {/* <Form.Item
                        name="gender"
                        label="Pilih Gender"
                        rules={[
                            {
                                required: true,
                                message: 'Pilih Gender kosong!'
                            },
                        ]}
                    >
                        <Select
                            options={[
                                {
                                    value: 'men',
                                    label: 'Laki - Laki',
                                },
                                {
                                    value: 'woman',
                                    label: 'Perempuan',
                                },
                            ]}
                        />
                    </Form.Item>

                    <Form.Item
                        name="address"
                        label="Alamat"
                        rules={[
                            {
                                required: true,
                                message: 'Alamat kosong!'
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Row>
                        <Col span={8} style={{ paddingRight: 10 }}>
                            <Form.Item
                                name="city"
                                label="Kota"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Kota kosong!'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8} style={{ paddingRight: 10, paddingLeft: 10 }}>
                            <Form.Item
                                name="province"
                                label="Provinsi"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Provinsi kosong!'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8} style={{ paddingLeft: 10 }}>
                            <Form.Item
                                name="country"
                                label="Negara"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Negara kosong!'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row> */}
                </Form>
            </Modal>
        </div>
    );
}


export default Employee;