import React from 'react';
import { Result } from 'antd';

const NotFound = () => (
    <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        // extra={<Button type="primary">Kenbali</Button>}
    />
);

export default NotFound;