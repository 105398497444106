import { Button, Card, Col, DatePicker, Row, Spin, Statistic, Table } from "antd"
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { apiCall } from "../../API/RestApi";
import { useSelector } from "react-redux";
import { timeFormat } from "../../Utils/formatDate";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import { currencyFormat } from "../../Utils/formatCurrency";
const { RangePicker } = DatePicker;
Chart.register(...registerables);

const titlePage = 'Dashboard';

function Dashboard() {
  const token = useSelector((state) => state.user.token);
  //parameter
  const [data, setData] = useState([]);
  const [loadLine, setLoadLine] = useState(0);
  const [valueDate, setValueDate] = useState();
  const [productPopular, setProductPopular] = useState([]);
  const [dataActivity, setDataActivity] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingActivity, setLoadingActivity] = useState(true);
  const [loadingPopular, setLoadingPopular] = useState(true);
  const [page, setPage] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [pagePopular, setPagePopular] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [dataLine, setDataLine] = useState({
    labels: [],
    datasets: [
      {
        label: 'Kemarin',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Hari ini',
        data: [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  });

  const options = {
    responsive: true,
    // maintainAspectRatio: false,
    // scales: {
    //   x: {
    //     ticks: {
    //       autoSkip: false,
    //     },
    //   },
    // },
    plugins: {
      // legend: {
      //   position: 'top',
      // },
      title: {
        display: true,
        text: 'Pendapatan Hari ini',
      },
    },
  };

  const columnActivity = [
    {
      title: 'No',
      dataIndex: 'no',
      key: "no",
      render: (id, record, index) => { ++index; return index },
    },
    {
      title: 'Tanggal',
      dataIndex: 'created_at',
      key: "created_at",
      render: (id, record, index) => {
        return timeFormat(record.created_at);
      },
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: "username",
      render: (id, record, index) => {
        if (record.user !== null) {
          return record.user.username;
        }
      },
    },
    {
      title: 'Menu',
      dataIndex: 'menu',
      key: "menu",
    },
    {
      title: 'Deskripsi',
      dataIndex: 'desc',
      key: "desc",
    },
  ];

  const columnPopular = [
    {
      title: 'No',
      dataIndex: 'no',
      key: "no",
      render: (id, record, index) => { ++index; return index },
    },
    {
      title: 'Kode Produk',
      dataIndex: 'product_code',
      key: "product_code",
    },
    {
      title: 'Nama Produk',
      dataIndex: 'product_name',
      key: "product_name",
    },
    {
      title: 'Harga',
      dataIndex: 'price',
      key: "price",
      render: (id, record) => {
        return currencyFormat(record.price);
      },
    },
    {
      title: 'Terjual',
      dataIndex: 'total',
      key: "total",
    },
  ];

  useEffect(() => {
    if (loadLine === 0) {
      setLoading(true);
      getApi('GET', '/analysis', 'analysis');
    }
    getApi('GET', '/useractivity?page=' + page.pagination.current, 'activity');

    if (valueDate) {
      if (valueDate[0] && valueDate[1]) {
        var startDate = valueDate[0];
        var endDate = valueDate[1];
        getApi('GET', '/productpopular?page=' + pagePopular.pagination.current + '&startDate=' + startDate + '&endDate=' + endDate, 'productpopular');
      }
    } else {
      getApi('GET', '/productpopular?page=' + pagePopular.pagination.current, 'productpopular');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(page), JSON.stringify(pagePopular)]);

  const getApi = (method, url, type) => {
    const dataReq = {
      method: method,
      url: url,
      data: {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    };
    apiCall(dataReq).then((res) => {
      if (res.status === 200) {
        switch (type) {
          case 'analysis':
            setData(res.data);
            var labels = [];
            var data_before = [];
            var data_now = [];

            // Mengambil data dari res.data.outlet_before
            res.data.outlet_before.forEach(outlet => {
              labels.push(outlet.outlet_name);
              data_before.push(outlet.total_grand_total);

              // Inisialisasi data_now dengan nilai 0 untuk setiap outlet
              data_now.push(0);
            });

            // Memproses data dari res.data.outlet_now jika ada
            if (res.data.outlet_now.length > 0) {
              res.data.outlet_now.forEach(outlet => {
                // Cari indeks outlet di data_before berdasarkan outlet_id
                const index = res.data.outlet_before.findIndex(item => item.outlet_id === outlet.outlet_id);

                // Jika ditemukan, perbarui nilai data_now di indeks yang sesuai
                if (index !== -1) {
                  data_now[index] = outlet.total_grand_total;
                }
              });
            }

            var dl = {
              labels,
              datasets: [
                {
                  label: 'Kemarin',
                  data: data_before,
                  borderColor: 'rgb(255, 99, 132)',
                  backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                  label: 'Hari ini',
                  data: data_now,
                  borderColor: 'rgb(53, 162, 235)',
                  backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
              ],
            };

            setDataLine(dl);
            setLoadLine(1);
            setLoading(false);
            break;
          case 'activity':
            setPage({
              ...page,
              pagination: {
                ...page.pagination,
                total: res.data.count
              }
            });
            setDataActivity(res.data.rows);
            setLoadingActivity(false);
            break;
          case 'productpopular':
            setPagePopular({
              ...pagePopular,
              pagination: {
                ...pagePopular.pagination,
                total: res.total_count
              }
            });
            setProductPopular(res.data);
            setLoadingPopular(false);
            break;

          default:
            setLoadingActivity(false);
            setLoadingPopular(false);
            setLoading(false);
            break;
        }
      } else {
        setLoadingActivity(false);
        setLoadingPopular(false);
        setLoading(false);
        console.log(res.message);
      }

    }).catch((e) => {
      setLoadingActivity(false);
      setLoadingPopular(false);
      setLoading(false);
      console.log(e.message);
    })
  }


  const handleTableChange = (pagination, filters, sorter) => {
    setPage({
      pagination,
      filters,
      ...sorter,
    });
    if (pagination.pageSize !== page.pagination?.pageSize) {
      setDataActivity([]);
    }
  };


  const handleTableChange2 = (pagination, filters, sorter) => {
    setPagePopular({
      pagination,
      filters,
      ...sorter,
    });
    if (pagination.pageSize !== page.pagination?.pageSize) {
      setProductPopular([]);
    }
  };

  const handleSearch = () => {
    if (valueDate[0] && valueDate[1]) {
      var startDate = valueDate[0];
      var endDate = valueDate[1];
      getApi('GET', '/productpopular?startDate=' + startDate + '&endDate=' + endDate, 'productpopular');
    }
  };

  return (
    <>
      <Helmet>
        <title>{titlePage}</title>
      </Helmet>
      {
        loading === false ? (
          <>
            <Row gutter={16} style={{ marginBottom: 20 }}>
              <Col span={8}>
                <Card bordered={false} style={{ borderRadius: 8, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
                  <Statistic
                    title="Total Penjualan Kemarin"
                    value={data.before ? Number(data.before.total_price_transaction) : 0}
                    valueStyle={{ color: '#888' }}
                    prefix={'Rp. '}
                    tooltip={{ title: 'Total penjualan yang terjadi kemarin' }}
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card bordered={false} style={{ borderRadius: 8, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
                  <Statistic
                    title="Total Penjualan Hari Ini"
                    value={data.now ? Number(data.now.total_price_transaction) : 0}
                    valueStyle={{ color: '#3f8600' }}
                    prefix={'Rp. '}
                    tooltip={{ title: 'Total penjualan yang terjadi hari ini' }}
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card bordered={false} style={{ borderRadius: 8, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
                  <Statistic
                    title="Total Customer Terdaftar"
                    value={data.total_customer}
                    valueStyle={{ color: '#d6006e' }}
                    tooltip={{ title: 'Jumlah total customer yang terdaftar' }}
                  />
                </Card>
              </Col>
            </Row>
            <div style={{ overflowX: 'scroll', width: '100%' }}>
              <div style={{ minWidth: '1500px' }}>
                <Bar options={options} data={dataLine} />
              </div>
            </div>

            <hr style={{ margin: '40px 0' }} />
            <Row gutter={16}>
              <Col span={12}>
                <h3 style={{ marginTop: 10 }}>Aktivitas Karyawan Hari Ini</h3>
                <Table
                  size="small"
                  columns={columnActivity}
                  dataSource={dataActivity}
                  loading={loadingActivity}
                  pagination={page.pagination}
                  onChange={handleTableChange}
                  bordered
                  style={{ borderRadius: 8 }}
                />
              </Col>
              <Col span={12}>
                <h3 style={{ marginTop: 10 }}>Produk Populer Hari Ini</h3>
                <RangePicker
                  style={{ marginBottom: 16 }}
                  onChange={(ev, date) => setValueDate(date)}
                />
                <Button onClick={handleSearch} type="primary" style={{ marginLeft: 10 }}>Cari</Button>
                <Table
                  size="small"
                  columns={columnPopular}
                  dataSource={productPopular}
                  loading={loadingPopular}
                  pagination={pagePopular.pagination}
                  onChange={handleTableChange2}
                  bordered
                  style={{ borderRadius: 8 }}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
        )
      }
    </>
  );

};
export default Dashboard;