
import { Alert, Button, Col, Form, Input, Modal, Row, Select, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
// import { currencyFormat } from '../../Utils/formatCurrency';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { dateFormat } from '../../Utils/formatDate';
import { formatNumber } from '../../Utils/formatNumber';

function Membership() {
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [form] = Form.useForm();
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);
    const [outlet, setOutlet] = useState([]);
    const [outletId, setOutletId] = useState([]);
    const [checkOutlet, setCheckOutlet] = useState(false);

    // //Parameter
    const titlePage = 'Membership';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: true,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        // {
        //     title: 'Nama Toko',
        //     dataIndex: 'outlet',
        //     key: "outlet",
        //     sorter: true,
        //     render: (id, record, index) => {
        //         if (record.outlet !== null)
        //             return record.outlet.outlet_name;
        //         else
        //             return 'Tidak Ada Outlet'
        //     },
        // },
        {
            title: 'Tanggal Terdaftar',
            dataIndex: 'created_at',
            key: "created_at",
            sorter: true,
            render: (id, record, index) => { return dateFormat(record.created_at); },
        },
        {
            title: 'Nama Customer',
            dataIndex: 'name',
            key: "name",
            sorter: true,
        },
        {
            title: 'Nomor HP',
            dataIndex: 'phone',
            key: "phone",
            sorter: true,
        },
        {
            title: 'Total Point',
            dataIndex: 'point',
            key: "point",
            sorter: true,
            render: (id, record, index) => { return formatNumber(record.point) + ' point' },
        },
        // {
        //     title: 'Action',
        //     dataIndex: 'action',
        //     key: 'action',
        //     render: (text, record) => {
        //         return (
        //             <>
        //                 <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal(record)}>
        //                     <EditOutlined />
        //                     <b style={{ marginLeft: 5 }}>Ubah</b>
        //                 </Button>
        //             </>
        //         );
        //     },
        // },
    ];

    // const TransColumns = [
    //     {
    //         title: 'No Struk',
    //         dataIndex: 'transaction_code',
    //         sorter: true,
    //         key: "transaction_code",
    //     },
    //     {
    //         title: 'Tanggal Transaksi',
    //         dataIndex: 'created_at',
    //         key: "created_at",
    //         sorter: true,
    //         render: (id, record, index) => { return dateFormat(record.created_at) },
    //     },
    //     {
    //         title: 'Nama Outlet',
    //         dataIndex: 'outlet',
    //         key: "outlet",
    //         sorter: true,
    //         render: (id, record, index) => {
    //             if (record.outlet !== null)
    //                 return record.outlet.outlet_name;
    //             else
    //                 return 'Tidak Ada Outlet'
    //         },
    //     },
    //     {
    //         title: 'Total (Rp)',
    //         dataIndex: 'grand_total',
    //         key: "grand_total",
    //         sorter: true,
    //         render: (id, record, index) => { return currencyFormat(record.grand_total); },
    //     },
    //     {
    //         title: 'Point',
    //         dataIndex: 'point',
    //         key: "point",
    //         sorter: true,
    //         render: (id, record, index) => {
    //             if (record.point !== null)
    //                 if (record.point.status === 'Approved')
    //                     return <p style={{ fontSize: 12, color: "green" }}>{'+' + record.point.point}</p>;
    //                 else if (record.point.status === 'Rejected')
    //                     return <p style={{ fontSize: 12, color: "red" }}>{'-' + record.point.point}</p>;
    //                 else
    //                     return <p style={{ fontSize: 12, color: "orange" }}>{'-' + record.point.point}</p>;
    //             else
    //                 return null;
    //         },
    //     },
    //     {
    //         title: 'Status',
    //         dataIndex: 'status',
    //         key: "status",
    //         sorter: true,
    //         render: (id, record, index) => {
    //             if (record.point !== null)
    //                 switch (record.point.status) {
    //                     case 'Process':
    //                         return <p style={{ fontSize: 12, color: "orange" }}>Sedang Diproses</p>
    //                     case 'Approved':
    //                         return <p style={{ fontSize: 12, color: "green" }}>Telah Disetujui</p>
    //                     default:
    //                         return <p style={{ fontSize: 12, color: "red" }}>Telah Ditolak</p>
    //                 }
    //             else
    //                 return null;
    //         },
    //     },
    // ]

    useEffect(() => {
        if (outlet.length === 0 && checkOutlet === false) {
            getApi('GET', '/outlet?nopage=true', 'outlet');
        }
        var outlet_to_str = '';
        if (outletId.length > 0) {
            outlet_to_str = '&outletId=[' + outletId.toString() + ']';
        }
        getApi('GET', '/customer?search=' + search + outlet_to_str + '&page=' + page.pagination.current, 'customer');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert, outletId]);

    const getApi = (method, url, type) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'customer':
                        var row = [];
                        for (let i = 0; i < res.data.rows.length; i++) {
                            var item = res.data.rows[i];
                            res.data.rows[i].key = item.customer_id
                            row.push({
                                'No': i + 1,
                                'Nama Outlet': item.outlet !== null ? item.outlet.outlet_name : 'Tidak Ada Outlet',
                                'Tanggal Terdaftar': dateFormat(item.created_at),
                                'Nama Customer': item.name,
                                'Nomor HP': item.phone,
                                'Total Point': item.point
                            });
                        }
                        setData(res.data.rows);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.count
                            }
                        });
                        setLoading(false);
                        break;
                    case 'export':
                        exportToExcel(res.data.rows);
                        setLoading(false);
                        break;

                    case 'outlet':
                        var o = [];
                        var o2 = [];
                        for (let i = 0; i < res.data.length; i++) {
                            if (res.data[i] !== null) {
                                o = [...o, {
                                    value: res.data[i].outlet_id,
                                    label: res.data[i].outlet_name
                                }];

                                o2 = [...o2, res.data[i].outlet_id];
                            }
                        }
                        setOutlet(o);
                        setOutletId(o2);
                        setCheckOutlet(true);
                        setLoading(false);
                        break;
                    default:
                        setLoading(false);
                        break;
                }
            } else {
                setLoading(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const exportToExcel = (customerData) => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const fileName = 'list_membership';
    
        // Ambil data dari res.rows
        const apiData = customerData.map((row, index)  => ({
            'No': index + 1,
            'Tanggal Daftar': row.created_at,
            'Nama': row.name,
            'Nomor HP': row.phone,
            'Point': row.point
        }));
    
        // Buat worksheet dari data yang telah diubah
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    
        // Konversi data menjadi file Excel
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const op = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(op, fileName + fileExtension);
    };

    const postApi = (method, values) => {
        setLoading(true);
        setIsAlert(false);
        const dataReq = {
            method: method,
            url: '/customer',
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        }
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setdataModal(null);
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const onFinish = (ev) => {
        if (dataModal != null) {
            ev.customer_id = dataModal.customer_id;
            postApi('PUT', ev);
        } else {
            postApi('POST', ev);
        }
    }

    // const openModal = (ev) => {
    //     form.setFieldsValue({
    //         outlet_id: ev.outlet_id,
    //         phone: ev.phone,
    //         name: ev.name
    //     })
    //     setdataModal(ev);
    //     setIsModal(true);
    // }

    const closeModal = () => {
        setdataModal(null);
        setIsModal(false);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const exportToCSV = () => {
        getApi('GET', '/customer?nopage=true', 'export');
    };

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Membership</h3>
            <Row>
                <Col span={8}>
                    <Search
                        placeholder="Pencarian Nomor HP"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10, marginRight: 10 }} type="primary" onClick={() => exportToCSV()}>
                        <b style={{ marginLeft: 5 }}>Export Excel</b>
                    </Button>
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => setIsModal(true)}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah Customer</b>
                    </Button>
                </Col>
            </Row>

            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            // expandable={{
            //     expandedRowRender: (record) => (
            //         <Table
            //             size="middle"
            //             columns={TransColumns}
            //             dataSource={record.transactions}
            //         />
            //     ),
            //     // rowExpandable: (record) => record.transactions.length > 0,
            // }}
            />
            <Modal title="Form Membership" open={isModal} onOk={form.submit} onCancel={() => closeModal()} okText="Simpan" >
                <Form form={form} name="form_membership" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="outlet_id"
                        label="Pilih Outlet"
                        rules={[
                            {
                                required: true,
                                message: 'Pilih Outlet kosong!'
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            style={{
                                marginBottom: 10
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            placeholder="Pilih Outlet"
                            options={outlet} />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label="Nama Customer"
                        rules={[
                            {
                                required: true,
                                message: 'Nama Customer kosong!'
                            },
                        ]}
                    >
                        <Input placeholder='Nama Customer' />
                    </Form.Item>
                    {
                        dataModal === null &&
                        <Form.Item
                            name="phone"
                            label="Nomor Telepon"
                            rules={[
                                {
                                    required: true,
                                    message: 'Nomor Telepon kosong!'
                                },
                            ]}
                        >
                            <Input placeholder='Nomor Telepon' />
                        </Form.Item>
                    }
                </Form>
            </Modal>
        </>
    );
}

export default Membership;