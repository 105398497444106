
import storage from 'redux-persist/lib/storage';
import { persistCombineReducers } from 'redux-persist';
import userReducers from './userReducers';
import printReducers from './printReducers';

const persistConfig = {
  key: 'root',
  storage: storage,
  timeout: null,
}

const rootReducer = persistCombineReducers(persistConfig, {
  'user': userReducers,
  'print': printReducers
});

export default rootReducer;
