
import { Col, Row, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { useSelector } from 'react-redux';


function ListItemTransfer() {
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [search] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    // const [isAlert, setIsAlert] = useState(false);
    // const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Pengiriman Barang';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: true,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Nomor',
            dataIndex: 'number',
            key: "number",
            sorter: true,
        },
        {
            title: 'Tanggal',
            dataIndex: 'transDate',
            key: "transDate",
            sorter: true,
        },
        {
            title: 'Tipe Proses',
            dataIndex: 'itemTransferTypeName',
            key: "itemTransferTypeName",
            sorter: true,
        },
        {
            title: 'Gudang Tujuan/Dari',
            dataIndex: 'sendto',
            key: "sendto",
            sorter: true,
            render: (id, record, index) => { 
                return record.referenceWarehouse.name
            },
        },
        {
            title: 'Gudang',
            dataIndex: 'warehouse',
            key: "warehouse",
            sorter: true,
            render: (id, record, index) => { 
                return record.warehouse.name
            },
        },
        {
            title: 'Keterangan',
            dataIndex: 'description',
            key: "description",
            sorter: true,
        },
        {
            title: 'Status Pengiriman',
            dataIndex: 'itemTransferOutStatusName',
            key: "itemTransferOutStatusName",
            sorter: true,
        },
    ];

    useEffect(() => {
        getApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page)]);

    const getApi = () => {
        setLoading(true);
        const dataReq = {
            method: "GET",
            url: '/accurate/itemtransfer?search=' + search + '&page=' + page.pagination.current+'&pageSize=10',
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setData(res.data.d);
                setPage({
                    ...page,
                    pagination: {
                        ...page.pagination,
                        total: res.data.sp.pageCount                    }
                });
                setLoading(false);
            } else {
                setLoading(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }




    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    // const searchData = (value) => {
    //     setSearch(value);
    //     setPage({
    //         pagination: {
    //             current: 1,
    //             pageSize: 10,
    //         },
    //     });
    // }

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {/* {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />} */}
            <h3>Daftar Pengiriman Barang</h3>
            <Row style={{marginBottom:10}}>
                <Col span={8}>
                    {/* <Search
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    /> */}
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    );
}

export default ListItemTransfer;