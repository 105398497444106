
import * as XLSX from "xlsx";
import { apiCall } from "../../API/RestApi";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";

function ImportFile() {

    const token = useSelector((state) => state.user.token);

    const sendApi = (method, url, data) => {
        
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: data,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            console.log(res);
        }).catch((e) => {
            console.log(e.message);
        })
    }

    const importAccurate = (file) => {
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                const workbook = XLSX.read(result, { type: "binary" });
                for (const Sheet in workbook.Sheets) {
                    XLSX.utils.sheet_to_row_object_array(workbook.Sheets['Barang & Jasa']);
                    if (workbook.Sheets.hasOwnProperty(Sheet)) {
                        var data = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[Sheet]);
                        var arr = [];
                        data.forEach(x => {
                            if (x['No.'] != null) {
                                arr.push({
                                    category_name: x['Kategori Barang'],
                                    product_code: x['Kode Barang'],
                                    product_name: x['Nama Barang'],
                                    min_order: x['Minimum Beli'],
                                    isActive: x['Non Aktif'],
                                    description: x['Catatan'],
                                    variant_product: {
                                        barcode_code: x['UPC/Barcode'],
                                        variant_name: x['Jenis Barang'],
                                        price: x['Nilai Satuan'],
                                        stock: -1,
                                        min_order: 1,
                                        isActive: x['Non Aktif'],
                                        satuan: x['Satuan Saldo Awal'] !== null ? x['Satuan Saldo Awal'] : x['Satuan'],
                                    },
                                    outlet: [
                                        {
                                            outlet_name: x['Gudang Saldo Awal'] != null ? x['Gudang Saldo Awal'] : x['Cabang Saldo'],
                                            price: x['Nilai Satuan'],
                                            stock: -1,
                                        }
                                    ]
                                });
                            } else {
                                var indexProd = arr.findIndex(o => o.product_name === x['Nama Barang']);
                                arr[indexProd].outlet.push({
                                    outlet_name: x['Gudang Saldo Awal'] != null ? x['Gudang Saldo Awal'] : x['Cabang Saldo'],
                                    price: x['Nilai Satuan'],
                                    stock: -1,
                                });
                            }
                        });
                        sendApi('POST', '/importproduct', arr);
                    }
                }
                // console.log(arr)
                console.log("upload success!");
            } catch (e) {
                console.log("file type is incorrect!");
            }
        };
        fileReader.readAsBinaryString(file);
    };


    const importExcel = (file) => {
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                const workbook = XLSX.read(result, { type: "binary" });
                for (const Sheet in workbook.Sheets) {
                    XLSX.utils.sheet_to_row_object_array(workbook.Sheets['Barang & Jasa']);
                    if (workbook.Sheets.hasOwnProperty(Sheet)) {
                        var data = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[Sheet]);
                        var arr = [];
                        data.forEach(x => {
                            var diskon = x['Diskon'] !== undefined ? (x['Diskon'] * 100) : 0;
                            var diskon2 = x['Diskon 2'] !== undefined ? (x['Diskon 2'] * 100) : 0;
                            var diskon_harga = null;
                            if (diskon !== undefined && diskon !== 0) {
                                diskon_harga = Number(x['Harga Barang']) * Number(x['Diskon']);
                            }
                            var stock = x['Stok'] !== undefined ? x['Stok'] : -1;
                            var variant_name = x['Varian'] !== undefined ? x['Varian'] : '';
                            var barcode_code = x['Barcode'] !== undefined ? x['Barcode'] : '';
                            var min_order = x['Minimum Beli'] !== undefined ? x['Minimum Beli'] : 1;
                            var status = false;
                            if (x['Status (Aktif/Tidak Aktif)'] !== undefined) {
                                status = x['Status (Aktif/Tidak Aktif)']
                            }
                            var desc =  x['Catatan'] !== undefined ?  x['Catatan'] : null;
                            var discount_start = x['Periode Diskon Mulai'] !== undefined ? x['Periode Diskon Mulai'] : null;
                            var discount_end = x['Periode Diskon Berakhir'] !== undefined ? x['Periode Diskon Berakhir'] : null;
                            var arr_data = {
                                category_name: x['Kategori'],
                                product_code: x['Kode Barang'],
                                product_name: x['Nama Barang'],
                                min_order: min_order,
                                isActive:status,
                                description: desc,
                                variant_product: {
                                    barcode_code: barcode_code,
                                    variant_name: variant_name,
                                    price: x['Harga Barang'],
                                    stock: stock,
                                    min_order: min_order,
                                    isActive:status,
                                    satuan: x['Satuan']
                                },
                                outlet: [
                                    {
                                        outlet_name: x['Outlet'],
                                        price: x['Harga Jual'],
                                        stock: stock
                                    }
                                ],
                            };
                            if (x['Diskon'] !== null && diskon !== 0) {
                                arr_data.discount = {
                                    discount_percent: diskon,
                                    discount_percent_2: diskon2,
                                    discount_price: diskon_harga,
                                    price_after_discount: x['Harga Jual'],
                                    discount_start: discount_start,
                                    discount_end: discount_end
                                }
                            }
                            arr.push(arr_data);

                        });
                        console.log(arr)
                        // sendApi('POST', '/importproduct', arr);
                    }
                }
                // console.log(arr)
                console.log("upload success!");
            } catch (e) {
                console.log("file type is incorrect!");
            }
        };
        fileReader.readAsBinaryString(file);
    };

    const onAccurateImportToPOS = event => {
        const { files } = event.target;
        if (files.length === 1) {
            importAccurate(files[0]);
        }
    };

    const onExcelImportToPOS = event => {
        const { files } = event.target;
        if (files.length === 1) {
            importExcel(files[0]);
        }
    }

    return (
        <div>
            {/* <Upload
          name="file"
          accept=".xls, .xlsx"
          onChange={e => uploadChanged(e)}
        >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload> */}
            <Row>
                <Col>
                    <b>Accurate to POS BOLDE</b><br />
                    <input className="file-uploader" type="file" accept=".xlsx, .xls" onChange={onAccurateImportToPOS} />
                </Col>
                <Col>
                    <b>Excel to POS BOLDE</b><br />
                    <input className="file-uploader" type="file" accept=".xlsx, .xls" onChange={onExcelImportToPOS} />
                </Col>
            </Row>
        </div>
    );
}

export default ImportFile;