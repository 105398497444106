

const INITIAL_STATE = {
	port: null,
}

const printReducers = (state = INITIAL_STATE, action = {}) => {
	switch (action.type) {
		case "UPDATE_PORT":
			return {
				...state,
				port: action.port
			};
		default:
			return state;
	}
};

export default printReducers;