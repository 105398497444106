import { Alert, Button, Col, DatePicker, Form, InputNumber, Modal, Row, Table, TreeSelect } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import { modalError, modalSuccess } from '../../Component/Modal/ModalLayout';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { currencyFormat } from '../../Utils/formatCurrency';
import moment from 'moment';
const { RangePicker } = DatePicker;

function SetOutletProductVariant() {
    const token = useSelector((state) => state.user.token);
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    //LIBRARY
    const [form] = Form.useForm();
    const location = useLocation();
    const [data, setData] = useState([]);
    const [product, setProduct] = useState([]);
    const [productVariant, setProductVariant] = useState([]);
    const [warehouse, setWarehouse] = useState([]);
    const [outlet, setOutlet] = useState([]);

    const [productVal, setProductVal] = useState(null);
    const [warehouseVal, setWarehouseVal] = useState(null);
    const [productVariantVal, setProductVariantVal] = useState(null);
    const [outletVal, setOutletVal] = useState(null);

    const [modalDiskon, setModalDiskon] = useState(false);
    const [indexModalDiskon, setIndexModalDiskon] = useState(null);

    const [loading, setLoading] = useState(false);
    // const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Produk';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: true,
            key: "no",
            render: (text, record, index) => (page - 1) * 10 + index + 1
        },
        {
            title: 'Nama Outlet',
            dataIndex: 'outlet_name',
            key: "outlet_name",
            sorter: true,
        },
        {
            title: 'Nama Barang',
            dataIndex: 'product_name',
            key: "product_name",
            sorter: true,
        },
        {
            title: 'Varian',
            dataIndex: 'variant_product_name',
            key: "variant_product_name",
            sorter: true,
        },
        {
            title: 'Stok',
            dataIndex: 'stock',
            key: "stock",
            sorter: true,
            render: (text, record, index) => {
                return (
                    <InputNumber
                        addonBefore="Qty"
                        style={{
                            width: "90%",
                        }}
                        defaultValue={Number(record.stock)}
                        onChange={(ev) => onInputTable(ev, index, 'stock')}
                        placeholder='Stok'
                    />
                );
            }
        },
        {
            title: 'Harga Barang',
            dataIndex: 'price',
            key: "price",
            sorter: true,
            render: (text, record, index) => {
                return (
                    <InputNumber
                        addonBefore="Rp."
                        style={{
                            width: "90%",
                        }}
                        key={(page - 1) * 10 + index}
                        onChange={(ev) => onInputTable(ev, index, 'price')}
                        placeholder='Harga Barang'
                        defaultValue={record.price}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                );
            }
        },
        {
            title: 'Gudang',
            dataIndex: 'warehouse_name',
            key: "warehouse_name",
            sorter: true,
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: "Action",
            sorter: true,
            render: (text, record, index) => {
                return (
                    <Row >
                        <Button type="primary" onClick={() => openModalDiscount(record, index)}>
                            Atur Diskon
                        </Button>
                        <Button type="text" onClick={() => delData(record, index)}>
                            <CloseOutlined style={{ color: "red" }} size={25} />
                        </Button>
                    </Row>
                );
            }
        },
    ];

    const openModalDiscount = (row, index) => {
        if (row.discount !== null) {
            form.setFieldsValue({
                discount_percent: row.discount.discount_percent,
                discount_percent_2: Number(row.discount.discount_percent_2),
                price_after_discount: row.discount.price_after_discount,
                discount_date: [moment(row.discount.discount_start), moment(row.discount.discount_end)]
            })
        }
        setIndexModalDiskon(index)
        setModalDiskon(true);
    }

    const delData = (record, index) => {
        var row = JSON.parse(JSON.stringify(data));
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus ' + record.product_name + ' - ' + record.variant_product_name + ' ini?',
            onOk: async () => {
                await getApi("DELETE", '/outletproduct?productId=' + record.product_id + '&outletId=' + record.outlet_id, 'deleteData')
                row.splice(index, 1);
                setData(row);
            },
        });
    }

    useEffect(() => {
        getDataRecord();
        getApi("GET", '/product?nopage=true', 'product');
        getApi("GET", '/warehouse?nopage=true', 'warehouse');
        getApi("GET", '/outlet?nopage=true', 'outlet');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAlert]);

    const getDataRecord = () => {
        const state = location.state;
        if (state != null) {
            getApi("GET", '/outletproduct?nopage=true&outletId=' + state.item, 'outletproduct');
        }
    }

    const onInputTable = (ev, index, type) => {
        var i = (page - 1) * 10 + index;
        data[i][type] = ev;
        setData(data);
    }

    const getApi = (method, url, type, value = null) => {
        setLoading(true);
        const dataReq = {
            method,
            url,
            data: {
                data: value,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setTextAlert(res.message);
                switch (type) {
                    case 'product':
                        var p = [];
                        for (let i = 0; i < res.data.rows.length; i++) {
                            p.push({
                                value: JSON.stringify(res.data.rows[i]),
                                label: res.data.rows[i].product_name
                            });
                        }
                        setProduct(p);
                        setLoading(false);
                        break;

                    case 'warehouse':
                        var w = [];
                        for (let i = 0; i < res.data.length; i++) {
                            w.push({
                                value: res.data[i].warehouse_id,
                                label: res.data[i].warehouse_name
                            });
                        }
                        setWarehouse(w);
                        setLoading(false);
                        break;

                    case 'outlet':
                        var o = [];
                        for (let i = 0; i < res.data.length; i++) {
                            o.push({
                                value: res.data[i].outlet_id,
                                label: res.data[i].outlet_name
                            });
                        }
                        setOutlet(o);
                        setLoading(false);
                        break;

                    case 'addData':
                        modalSuccess('Berhasil Input Data');
                        setData([]);
                        setLoading(false);
                        break;

                    case 'updateData':
                        modalSuccess('Berhasil Input Data');
                        setData([]);
                        setLoading(false);
                        break;

                    case 'deleteData':
                        modalSuccess('Berhasil Hapus Data');
                        setLoading(false);
                        break;

                    case 'outletproduct':
                        var row = res.data.rows;
                        var arrData = [];
                        for (let i = 0; i < row.length; i++) {
                            var arr = {
                                outlet_id: row[i].outlet_product.outlet_id,
                                outlet_name: row[i].outlet_product.outlet.outlet_name,
                                product_id: row[i].outlet_product.product.product_id,
                                product_name: row[i].outlet_product.product.product_name,
                                variant_product_id: row[i].variant_product.variant_product_id,
                                variant_product_name: row[i].variant_product.variant_name,
                                stock: row[i].stock,
                                price: row[i].price,
                                warehouse_id: row[i].outlet_product.warehouse.warehouse_id,
                                warehouse_name: row[i].outlet_product.warehouse.warehouse_name,
                            };
                            if (row[i].discount !== null) {
                                arr.discount = {
                                    discount_percent: row[i].discount.discount_percent,
                                    discount_percent_2: row[i].discount.discount_percent_2,
                                    discount_price: row[i].discount.discount_price,
                                    price_after_discount: row[i].discount.price_after_discount,
                                    discount_date: [moment(row[i].discount.discount_start), moment(row[i].discount.discount_end)]
                                }
                            }
                            arrData.push(arr);
                        }
                        setOutletVal({
                            value: row[0].outlet_product.outlet_id,
                            label: row[0].outlet_product.outlet.outlet_name
                        })
                        setData(arrData);
                        break;
                    default:
                        setLoading(false);
                        break;
                }
            } else {
                setLoading(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const selectedProduct = (p, val) => {
        var v = JSON.parse(p)
        var o = [];
        for (let i = 0; i < v.variant_products.length; i++) {
            o.push({
                value: v.variant_products[i].variant_product_id,
                label: v.variant_products[i].variant_name ? v.variant_products[i].variant_name : v.variant_products[i].SKU,
            });
        }
        setProductVariant(o)
        setProductVal({ value: p, label: val });
        setProductVariantVal(null)
    }

    const addData = () => {
        const state = location.state
        if (outletVal !== null) {
            if (productVal !== null) {
                if (productVariantVal !== null) {
                    if (warehouseVal !== null) {
                        let obj = data.find(o => Number(o.variant_product_id) === Number(productVariantVal.value));
                        if (obj === undefined) {
                            var item = {
                                outlet_id: outletVal.value,
                                outlet_name: outletVal.label,
                                product_id: JSON.parse(productVal.value).product_id,
                                product_name: productVal.label,
                                variant_product_id: productVariantVal.value,
                                variant_product_name: productVariantVal.label,
                                stock: 0,
                                price: 0,
                                warehouse_id: warehouseVal.value,
                                warehouse_name: warehouseVal.label,
                                discount: null,
                            }
                            let setDatas = [].concat(data, item);
                            setData(setDatas);
                            if (state === null) {
                                setOutletVal(null)
                            }
                            setProductVal(null)
                            setProductVariantVal(null)
                            setWarehouseVal(null)
                        } else {
                            modalError('Produk ini telah di input')
                        }
                    } else {
                        modalError('Pilih Gudang terlebih dahulu')
                    }
                } else {
                    modalError('Pilih Vairan Produk terlebih dahulu')
                }
            } else {
                modalError('Pilih Produk terlebih dahulu')
            }
        } else {
            modalError('Pilih Outlet terlebih dahulu')
        }
    }

    const saveData = () => {
        getApi('POST', '/outletproduct', 'addData', data);
    }

    const saveDiscount = (ev) => {
        var discount_price = Number(data[indexModalDiskon].price) - Number(ev.price_after_discount);
        var discount_start;
        var discount_end;
        if (ev.discount_date) {
            discount_start = ev.discount_date[0].format(dateFormat);
            discount_end = ev.discount_date[1].format(dateFormat);
        }
        var dataDiscount = {
            discount_percent: ev.discount_percent,
            discount_percent_2: ev.discount_percent_2,
            discount_price: discount_price,
            price_after_discount: ev.price_after_discount,
            discount_start: discount_start,
            discount_end: discount_end
        }
        data[indexModalDiskon].discount = dataDiscount;
        setData(data);
        setIndexModalDiskon(null);
        setModalDiskon(false)
    }
    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <Row>
                <Col span={22}>
                    <h3>Persediaan Toko Produk</h3>
                    {
                        location.state === null &&
                        <>
                            <TreeSelect
                                showSearch
                                style={{
                                    width: 500,
                                    marginBottom: 10
                                }}
                                placeholder="Pilih Outlet"
                                allowClear
                                treeNodeFilterProp='label'
                                treeDefaultExpandAll
                                treeData={outlet}
                                onChange={(i, v) => setOutletVal({ value: i, label: v[0] })}
                            />
                            <br />
                        </>
                    }
                    <TreeSelect
                        showSearch
                        style={{
                            width: 500,
                            marginBottom: 10
                        }}
                        placeholder="Pilih Produk"
                        allowClear
                        treeNodeFilterProp='label'
                        treeDefaultExpandAll
                        treeData={product}
                        // onChange={(i, v) => console.log({value:i,label:v[0]})}
                        onChange={(i, v) => selectedProduct(i, v[0])}
                    />
                    <br />

                    <TreeSelect
                        showSearch
                        style={{
                            width: 500,
                            marginBottom: 10
                        }}
                        placeholder="Pilih Variant Produk"
                        allowClear
                        treeNodeFilterProp='label'
                        treeDefaultExpandAll
                        treeData={productVariant}
                        onChange={(i, v) => setProductVariantVal({ value: i, label: v[0] })}
                    />
                    <br />
                    <TreeSelect
                        showSearch
                        style={{
                            width: 500,
                            marginBottom: 10
                        }}
                        placeholder="Pilih Gudang"
                        allowClear
                        treeNodeFilterProp='label'
                        treeDefaultExpandAll
                        treeData={warehouse}
                        onChange={(i, v) => setWarehouseVal({ value: i, label: v[0] })}
                    />
                    <br />

                    <Button block type="primary" style={{ width: 100, marginBottom: 10 }} onClick={() => addData()}>
                        <b style={{ marginLeft: 5 }}>Tambah</b>
                    </Button>
                </Col>
                <Col span={2}>
                    <Button block type="primary" style={{ width: 100, marginBottom: 10 }} onClick={() => saveData()}>
                        <b style={{ marginLeft: 5 }}>Simpan</b>
                    </Button>
                </Col>
            </Row>
            {/* <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            /> */}
            <Table
                size="small"
                columns={columns}
                dataSource={data}
                loading={loading}
                pagination={{
                    onChange(current) {
                        setPage(current);
                    }
                }}
            />
            <Modal title="Form Persediaan Produk" open={modalDiskon} onOk={form.submit} onCancel={() => setModalDiskon(false)} okText="Simpan" >
                <Form form={form} name="form_category" layout="vertical" onFinish={saveDiscount}>
                    <div >
                        {
                            data[indexModalDiskon] &&
                            <div style={{ marginBottom: 10 }}>
                                <b>{data[indexModalDiskon].product_name}</b><br />
                                {currencyFormat(data[indexModalDiskon].price)}
                            </div>
                        }
                    </div>
                    <Form.Item
                        name="discount_percent"
                        label="Diskon 1 (%)"
                        rules={[
                            {
                                required: true,
                                message: 'Diskon kosong!'
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            max={100}
                            style={{ width: "100%" }}
                            formatter={(value) => `${value}`}
                            onChange={(v) => {
                                var dis = ((100 - Number(v)) / 100);
                                var price = data[indexModalDiskon].price;
                                var afterDis = dis * Number(price);
                                form.setFieldValue('price_after_discount', afterDis);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="discount_percent_2"
                        label="Diskon 2 (%)"
                        rules={[
                            {
                                required: false,
                                message: 'Diskon kosong!'
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            max={100}
                            style={{ width: "100%" }}
                            formatter={(value) => `${value}`}
                            onChange={(v) => {
                                var price = data[indexModalDiskon].price;
                                var discount_percent = form.getFieldValue('discount_percent');

                                var dis = ((100 - discount_percent) / 100) * Number(price);
                                var afterDis = ((100 - v) / 100) * Number(dis);

                                form.setFieldValue('price_after_discount', afterDis);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="price_after_discount"
                        label="Harga Setelah Diskon"
                        rules={[
                            {
                                required: true,
                                message: 'Harga Setelah Diskon kosong!'
                            },
                        ]}
                    >
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="discount_date"
                        label="Tanggal Diskon"
                        rules={[
                            {
                                required: false,
                                message: 'Tanggal Diskon kosong!'
                            },
                        ]}
                    >
                        <RangePicker showTime style={{ width: '100%' }} format={dateFormat} />
                    </Form.Item>
                </Form>
            </Modal>

        </>
    );
}

export default SetOutletProductVariant;