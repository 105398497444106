
import { Alert, Button, Col, Form, Input, Modal, Row, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined, SyncOutlined, LoadingOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';


function ListWarehouse() {
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        sorter:undefined
    });
    const [form] = Form.useForm();
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Gudang';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Nama Gudang',
            dataIndex: 'warehouse_name',
            key: "warehouse_name",
            sorter: true,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('edit', record)}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        var sorter = '';
        if(page.sorter !== undefined){
            var order = 'desc';
            if(page.sorter.order === 'ascend'){
                order = 'asc';
            }
            sorter = '&sorter=' + page.sorter.field + '&order=' + order;
        }
        getApi('/warehouse?search=' + search + '&page=' + page.pagination.current+sorter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (url) => {
        setLoading(true);
        const dataReq = {
            method: "GET",
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setData(res.data.rows);
                setPage({
                    ...page,
                    pagination: {
                        ...page.pagination,
                        total: res.data.count
                    }
                });
                setLoading(false);
                setLoadingBtn(false);
            } else {
                setLoading(false);
                setLoadingBtn(false);
            }
        }).catch((e) => {
            setLoading(false);
            setLoadingBtn(false);
            console.log(e.message);
        })
    }

    const postApi = (method, values) => {
        setLoading(true);
        setIsAlert(false);
        var url = '/warehouse';
        if (method === 'DELETE') {
            url = '/warehouse/' + values.warehouse_id;
        }
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setdataModal(null);
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', v),
        });
    }

    const openModal = (type, data) => {
        if (type === 'edit') {
            setdataModal(data)
            form.setFieldsValue({
                warehouse_name: data.warehouse_name,
                description: data.description
            })
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    }

    const onFinish = (ev) => {
        if (dataModal != null) {
            var data = {
                warehouse_id: dataModal.warehouse_id,
                warehouse_name: ev.warehouse_name,
            }
            postApi('PUT', data);
        } else {
            postApi('POST', ev);
        }
    }

    const closeModal = () => {
        setdataModal(null);
        setIsModal(false);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
            sorter: undefined,
        });
    }

    const syncProductAccurate = () => {
        setLoadingBtn(true);
        setLoading(true);
        getApi('/accurate/loadwarehouse?page=1&pageSize=100');
    }

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Gudang</h3>
            <Row>
                <Col span={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Button style={{ marginBottom: 10, marginRight: 10 }} type="primary" onClick={() => syncProductAccurate()}>
                        {
                            loadingBtn === true ?
                                <LoadingOutlined />
                                :
                                <SyncOutlined />
                        }
                        Sync Accurate
                    </Button>
                    <Search
                        disabled={loadingBtn}
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button disabled={loadingBtn} style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah Gudang</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal title="Form Gudang" open={isModal} onOk={form.submit} onCancel={() => closeModal()} okText="Simpan" >
                <Form form={form} name="form_warehouse" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="warehouse_name"
                        label="Nama Gudang"
                        rules={[
                            {
                                required: true,
                                message: 'Nama Gudang kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    {/* <Form.Item 
                        name="description" 
                        label="Deksripsi"
                        rules={[
                            {
                                required: false,
                                message: 'Deskripsi kosong!'
                            },
                        ]}
                        >
                        <Input.TextArea />
                    </Form.Item> */}
                </Form>
            </Modal>
        </>
    );
}

export default ListWarehouse;