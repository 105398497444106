import React from 'react';
import { Outlet } from 'react-router-dom';
// import { getToken } from '../../Utils/Storage';
import Login from '../../Views/Auth/Login';
import MainLayout from './Layout';
import { useSelector } from 'react-redux';

function ProtectedLayout() {
    const token = useSelector((state) => state.user.token);
    // const token = getToken();
    const status = token != null ? true : false;
    if (status)
        return (<MainLayout><Outlet /></MainLayout>);
    else
        return <Login />;
}

export default ProtectedLayout;