import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Image, Popover, Row } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { clearAllData } from '../../../../Utils/Storage';
import { useSelector } from 'react-redux';

function Headers() {
    const navigate = useNavigate();

    const s = useSelector((state) => state.user.user);
    const user = JSON.parse(s);

    //Menu Profile
    const text = <Button type="link" onClick={() => profile()}><span>Profile</span></Button>;
    const content = (
        <div>
            <Button type="link" danger onClick={() => logout()}>
                Log Out
            </Button>
        </div>
    );


    //Function
    const logout = () => {
        clearAllData();
        navigate('/login');
    }

    const profile = () => {
        navigate('/profile');
    }

    const full_name = user !== null && user !== undefined ? user.detail_user.first_name + ' ' + user.detail_user.last_name : "";
   return (
        <Header style={{ backgroundColor: "#d6006e" }} className="header">
            <Row>
                <Col span={16}>
                    <Image
                        preview={false}
                        width={50}
                        src="https://images.tokopedia.net/img/cache/215-square/shops-1/2020/3/13/8008242/8008242_3c4a241b-6bc7-41bc-9522-7f37722862a8.jpeg"
                    />
                </Col>
                <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button type="link" style={{
                        color: "#FFF"
                    }} danger onClick={() => profile()}>{full_name}</Button>
                    <Popover placement="bottom" title={text} content={content} trigger="click">
                        {/* <Avatar icon={<UserOutlined />} /> */}
                        {
                            user !== null && user !== undefined ?
                                <img src={user.detail_user.image} style={{width:32,height:32,borderRadius:20,objectFit: "cover",}} />
                                :
                                <Avatar icon={<UserOutlined />} />
                        }
                    </Popover>
                </Col>
            </Row>
        </Header>
    );
}

export default Headers;