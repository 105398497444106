import { Button, Card, Input } from 'antd';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';

function CheckPinCode() {
    const navigate = useNavigate();
    const location = useLocation();
    const [pinCode, setPinCode] = useState();

    //FUNCTION 
    const sendAPI = (data) => {
        const dataReq = {
            method: "POST",
            url: '/checkpincode',
            data: {
                data: data,
                headers: null
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                navigate('/changepassword/' + data.user_id);
            } else {
                modalError(res.message);
            }
        }).catch((e) => {
            modalError(e.message);
        })
    }
    const send = () => {
        var user_id = location.pathname.split('/')[2]
        var data = {
            user_id: user_id,
            verification_code: pinCode
        }
        sendAPI(data);
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <Card>
                <h2>Kode Verifikasi </h2>
                <Input
                    placeholder="Masukkan Kode Verifikasi"
                    onChange={(val) => setPinCode(val.target.value)}
                    value={pinCode}
                />
                <div style={{ float: 'right' }}>
                    <Button type='primary' style={{ marginTop: 10 }} onClick={send}>Kirim</Button>
                </div>
            </Card>
        </div>
    );
}


export default CheckPinCode;