import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const exportToExcelProductPromo = async (data) => {
    // Mengonversi data dengan nama field baru
    let mappedData = [];

    data.forEach((item, index) => {
        mappedData.push({
            'No': index+1,
            'Nama Barang': item.product_name,
            'Harga Normal': item.price_normal,
            'Diskon 1 (%)':item.discount_percent,
            'Diskon 2 (%)': item.discount_percent_2,
            'Harga Jual': item.price_sale,
        });
    });

    // Membuat worksheet Excel
    const worksheet = XLSX.utils.json_to_sheet(mappedData);

    // Membuat workbook dan menyimpan sebagai file Excel
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'produk_promo.xlsx');
};

export const exportToExcelProductPrice = async (data) => {
    // Mengonversi data dengan nama field baru
    let mappedData = [];

    data.forEach((item, index) => {
        mappedData.push({
            'No': index+1,
            'Kode Barang': item.product_code,
            'Nama Barang': item.product_name,
            'Harga Normal': item.price_normal,
            'Diskon 1 (%)':item.discount_percent,
            'Diskon 2 (%)': item.discount_percent_2,
            'Harga Jual': item.price_sale,
        });
    });

    // Membuat worksheet Excel
    const worksheet = XLSX.utils.json_to_sheet(mappedData);

    // Membuat workbook dan menyimpan sebagai file Excel
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'produk_harga.xlsx');
};


// Fungsi untuk menghitung panjang maksimum teks di setiap kolom
function getColumnWidths(data) {
    const maxWidth = 50; // Lebar maksimum kolom
    const columnWidths = [];

    // Iterasi untuk setiap kolom
    for (const item of data) {
        Object.keys(item).forEach((key, index) => {
            const columnLength = item[key] ? String(item[key]).length : 0;
            columnWidths[index] = columnWidths[index] || 0;
            if (columnLength > columnWidths[index] && columnLength < maxWidth) {
                columnWidths[index] = columnLength;
            }
        });
    }

    // Mengubah lebar kolom ke format yang dapat diterima oleh xlsx
    return columnWidths.map(width => ({ width: width > maxWidth ? maxWidth : width }));
}
