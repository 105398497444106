
import { Alert, Button, Checkbox, Col, Form, Input, Modal, Row, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined, SyncOutlined, PrinterOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';
import { currencyFormat } from '../../Utils/formatCurrency';
import moment from 'moment';
import { writePrintReturn } from '../../Utils/PrintThermal';


function ProductReturn() {
    const token = useSelector((state) => state.user.token);
    const serialPort = useSelector(state => state.print.port);
    const [data, setData] = useState([]);
    const [product, setProduct] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [dataSelect, setDataSelect] = useState([]);
    const [isModalReason, setIsModalReason] = useState(false);

    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [dataModal, setDataModal] = useState(null);
    const [dataTrans, setDataTrans] = useState(null);
    const [statusDetail, setStatusDetail] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [form] = Form.useForm();
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Retur Penjualan';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: true,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Kode Return',
            dataIndex: 'return_code',
            key: "return_code",
            sorter: true,
        },
        {
            title: 'Kode Transaksi',
            dataIndex: 'transaction_code',
            key: "transaction_code",
            sorter: true,
        },
        {
            title: 'Outlet',
            dataIndex: 'outlet_name',
            key: "outlet_name",
            sorter: true,
            render: (id, record, index) => { return record.outlet.outlet_name; },
        },
        {
            title: 'Deskripsi',
            dataIndex: 'desc',
            key: "desc",
            sorter: true,
            width: '30%',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button style={{ marginRight: 5 }} onClick={() => printReturn(record)}>
                            <div style={{ fontSize: 12 }}><PrinterOutlined /></div>
                        </Button>
                        {
                            record.status_accurate === true ?
                                <>
                                    <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('detail', record)}>
                                        <b style={{ marginLeft: 5 }}>Detail</b>
                                    </Button>
                                    <p style={{ color: 'green' }}>Telah terkirim Accurate</p>
                                </>
                                :
                                <>
                                    <Button type="default" disabled={record.status_accurate} style={{ marginRight: 10 }} onClick={() => modalSendAccurate(record)}>
                                        <SyncOutlined />
                                        <b style={{ marginLeft: 5 }}>Kirim Accurate</b>
                                    </Button>
                                    <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('edit', record)}>
                                        <EditOutlined />
                                        <b style={{ marginLeft: 5 }}>Ubah</b>
                                    </Button>
                                    <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                                        <DeleteOutlined />
                                        <b style={{ marginLeft: 5 }}>Hapus</b>
                                    </Button>
                                </>
                        }
                    </>
                );
            },
        },
    ];

    const productColumns = [
        {
            title: 'Select',
            dataIndex: 'select',
            key: 'select',
            render: (text, record, index) => (
                <Checkbox
                    checked={selectedRowKeys.includes(index)}
                    onChange={() => {
                        const newSelectedRowKeys = [...selectedRowKeys];
                        if (newSelectedRowKeys.includes(index)) {
                            const indexToRemove = newSelectedRowKeys.indexOf(index);
                            newSelectedRowKeys.splice(indexToRemove, 1);
                        } else {
                            newSelectedRowKeys.push(index);
                        }
                        setSelectedRowKeys(newSelectedRowKeys);
                    }}
                />
            ),
        },
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (text, record, index) => index + 1
        },
        {
            title: 'Nama Produk',
            dataIndex: 'product_name',
            key: "product_name",
            render: (text, record) => {
                if (record.variant_product.variant_name !== null)
                    return record.product.product_name + ' - ' + record.variant_product.variant_name;
                else
                    return record.product.product_name;
            }
        },
        {
            title: 'Harga Normal',
            dataIndex: 'price_normal',
            key: "price_normal",
            render: (text, record) => {
                return currencyFormat(Number(record.discount.price_after_discount) + Number(record.discount.discount_price));
            }
        },
        {
            title: 'Diskon (%)',
            dataIndex: 'discount_percent',
            key: "discount_percent",
            render: (text, record) => {
                return record.discount.discount_percent;
            }
        },
        {
            title: 'Diskon 2 (%)',
            dataIndex: 'discount_percent_2',
            key: "discount_percent_2",
            render: (text, record) => {
                return record.discount.discount_percent_2;
            }
        },
        {
            title: 'Harga Jual',
            dataIndex: 'price',
            key: "price",
            render: (text, record, index) => {
                return currencyFormat(record.price);
            }
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: "qty",
            render: (text, record, index) => {
                return record.qty;
            }
        },
        {
            title: 'Sub Total',
            dataIndex: 'subtotal',
            key: "subtotal",
            render: (text, record, index) => {
                return currencyFormat(record.subtotal);
            }
        },
    ];

    useEffect(() => {
        getApi('/productreturn?search=' + search + '&page=' + page.pagination.current, 'all');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);


    useEffect(() => {
        if (dataSelect.length > 0 && dataModal) {
            onSearchTrans(dataModal.transaction_code);
        }
    }, [dataSelect]);

    const printReturn = (item) => {
        getApi('/productreturn/' + item.transaction_return_id, 'print_return');

    }

    const getApi = (url, type, dataJson) => {
        setLoading(true);
        const dataReq = {
            method: "GET",
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'all':
                        setData(res.data.data);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.total_data
                            }
                        });
                        break;
                    case 'transaction':
                        if (res.data.count >= 1) {
                            var indexSelect = [];
                            for (let i = 0; i < res.data.rows[0].transaction_products.length; i++) {
                                const item = res.data.rows[0].transaction_products[i];
                                for (let i2 = 0; i2 < dataSelect.length; i2++) {
                                    const itemSelect = dataSelect[i2];
                                    if (item.variant_product.variant_product_id === itemSelect.variant_product_id) {
                                        indexSelect.push(i);
                                    }
                                }
                            }
                            setSelectedRowKeys(indexSelect);
                            setDataTrans(res.data.rows[0]);
                            setProduct(res.data.rows[0].transaction_products);
                        } else {
                            modalError('Data tidak ditemukan');
                        }
                        break;
                    case 'print_return':
                        var data_print = res.data;
                        if (serialPort !== null) {
                            writePrintReturn(serialPort, data_print);
                        }
                        break;
                    case 'send_to_accurate':
                        if (res.data.count >= 1) {
                            Modal.confirm({
                                title: 'NOTICE',
                                content: 'Anda ingin mengirim data ini?',
                                onOk: () => sendToAccurate(dataJson, res.data.rows[0].transaction_products)
                            });
                        } else {
                            modalError('Data tidak ditemukan');
                        }
                        break;


                    default:
                        break;
                }
                setLoading(false);
                setLoadingBtn(false);
            } else {
                setLoading(false);
                setLoadingBtn(false);
            }
        }).catch((e) => {
            setLoading(false);
            setLoadingBtn(false);
            console.log(e.message);
        })
    }

    const postApi = (method, url, values = null) => {
        setLoading(true);
        setIsAlert(false);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setDataModal(null);
                setLoadingBtn(false);
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoadingBtn(false);
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoadingBtn(false);
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (ev) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', '/productreturn/' + ev.transaction_return_id),
        });
    }

    const modalSendAccurate = (ev) => {
        getApi('/transaction?transcode=' + ev.transaction_code + '&status=all&isReturn=true', 'send_to_accurate', ev);
    }

    const sendToAccurate = (ev, prod) => {
        setLoading(true);
        setLoadingBtn(true);
        var returnDate = ev.created_at.split('T')[0];
        var dataReturn = {
            trans_date: ev.trans_date,
            return_date: returnDate,
            transaction_code: ev.transaction_code,
            outlet_id: ev.outlet_id,
            outlet_code: ev.outlet.outlet_code,
            outlet_name: ev.outlet.outlet_name,
            branch_name: ev.outlet.branch_name,
            return_code: ev.return_code,
            // item_invoices: [],
            item_return: [],
            item_payment: [],
            desc:ev.desc
        }

        var totalAmount = 0;

        // invoices
        for (let i = 0; i < prod.length; i++) {
            const item = prod[i];
            totalAmount += Number(item.subtotal);
            //     dataReturn.item_invoices.push({
            //         product_code: item.product.product_code,
            //         product_id: item.product.product_id,
            //         qty: item.qty,
            //         price: item.price,
            //         total_discount: item.discount != null ? Number(item.qty) * Number(item.discount.discount_price) : null,
            //         warehouse_name: item.warehouse.warehouse_name
            //     });
        }

        // return
        for (let i = 0; i < ev.product_returns.length; i++) {
            const item = ev.product_returns[i];
            dataReturn.item_return.push({
                product_code: item.product.product_code,
                product_id: item.product_id,
                qty: item.qty,
                price: item.discount != null ? Number(item.price) + Number(item.discount.discount_price) : item.price,
                total_discount: item.discount != null ? Number(item.qty) * Number(item.discount.discount_price) : null,
                price_payment: Number(item.subtotal),
                warehouse_name: item.warehouse.warehouse_name
            });
        }

        dataReturn.item_payment.push({
            price_payment: totalAmount,
            akun_no: ev.akun_no
        });
        postApi('POST', '/accurate/productreturn', dataReturn)
    }

    const openModal = (type, data) => {
        if (type === 'edit' || type === 'detail') {
            if (type === 'detail') {
                setStatusDetail(true);
            }
            setDataModal(data);
            var prodSelect = [];
            data.product_returns.forEach((data) => {
                prodSelect.push(data);
            });
            setDataSelect(prodSelect);
            form.setFieldsValue({
                desc: data.desc
            });
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    }

    const onFinish = (ev) => {
        const date = moment(dataTrans.created_at);
        const transDate = date.format('YYYY-MM-DD');
        var dataReturn = {
            trans_date: transDate,
            transaction_code: dataTrans.transaction_code,
            outlet_id: dataTrans.outlet_id,
            return_type: 'INVOICE',
            desc: ev.desc,
            fax: dataTrans.isPPN != null ? dataTrans.isPPN : true,
            is_deleted: dataTrans.status,
            item: [],
            akun_no: dataTrans.akun_no,
            payment_method: dataTrans.device_payment
        }
        for (let i = 0; i < selectedRowKeys.length; i++) {
            const item = product[selectedRowKeys[i]];
            var arr_prod = {
                product_id: item.product.product_id,
                variant_product_id: item.variant_product.variant_product_id,
                discount_id: item.discount.discount_id,
                price: item.price,
                qty: item.qty,
                subtotal: item.subtotal,
                warehouse_id: dataTrans.outlet.warehouse_id,
                bonus_id: item.bonus !== null ? item.bonus.bonus_id : null,
                fax: item.fax,
                is_active: true,
            }
            dataReturn.item.push(arr_prod);
        }

        if (dataModal != null) {
            postApi('PUT', '/productreturn/' + dataModal.transaction_return_id, dataReturn);
        } else {
            postApi('POST', '/productreturn', dataReturn);
        }
    }

    const closeModal = () => {
        setStatusDetail(false);
        setDataModal(null);
        setIsModal(false);
        setIsModalReason(false);
        setSelectedRowKeys([]);
        setDataTrans(null);
        setProduct([]);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const onSearchTrans = (ev) => {
        setSelectedRowKeys([]);
        getApi('/transaction?transcode=' + ev + '&status=all&isReturn=true', 'transaction');
    }

    const checkInput = () => {
        if(selectedRowKeys.length > 0){
            setIsModalReason(true);
        }else{
            modalError('Pilih Produk yang di return');
        }
    }

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Retur Penjualan</h3>
            <Row>
                <Col span={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Search
                        disabled={loadingBtn}
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button disabled={loadingBtn} style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah Produk Return</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal title="Form Produk Return" width={'80%'} open={isModal} footer onCancel={() => closeModal()} okText="Simpan" >
                {
                    statusDetail === false &&
                    <Search
                        placeholder="Masukkan Kode Transaksi"
                        allowClear
                        size="medium"
                        style={{ width: 300, marginBottom: 10 }}
                        onSearch={(ev) => onSearchTrans(ev)}
                    />
                }
                {
                    dataTrans !== null &&
                    <>
                        <div>
                            <p>Kode Transaksi : <b>{dataTrans.transaction_code}</b></p>
                            {dataModal !== null && <p>Deskripsi : {dataModal.desc}</p>}
                        </div>
                        <Table
                            size="middle"
                            columns={productColumns}
                            dataSource={product}
                        />
                        {
                            statusDetail === false &&
                            <Button type='primary' onClick={() => checkInput()}>Return Now</Button>
                        }
                    </>
                }
            </Modal>

            <Modal title="Alasan Return" open={isModalReason} onOk={form.submit} onCancel={() => setIsModalReason(false)} okText="Simpan" >
                <Form form={form} name="form" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="desc"
                        label="Deskripsi"
                        rules={[
                            {
                                required: true,
                                message: 'Deskripsi kosong!'
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default ProductReturn;