import { Button, Col, Form, Input, Row, Select, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { modalError, modalSuccess } from '../../Component/Modal/ModalLayout';
import { apiCall } from '../../API/RestApi';
// import { urlUser } from '../../Utils/globalUrl';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_USER } from '../../Redux/actions/userActions';

function Profile() {
    const token = useSelector((state) => state.user.token);
    const users = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState();
    const [file, setFile] = useState();
    const [user, setUser] = useState();
    const [countCall, setCountCall] = useState(0);

    useEffect(() => {
        callApi('GET', '/profile');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countCall]);

    const onFinish = (ev) => {
        const form = new FormData();
        form.append('detail_user_id', user.detail_user_id);
        if (file != null) {
            form.append('image', file);
        }
        for (const name in ev) {
            form.append(name, ev[name]);
        }
        callApi('PUT', '/profile', form);
    }

    const callApi = (method, url, values) => {
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                if (method === 'PUT') {
                    var f = form.getFieldsValue();
                    modalSuccess(res.message);
                    setCountCall(countCall + 1);
                    var s = JSON.parse(users);
                    s.username=f.username;
                    s.email= f.email;
                    s.phone= f.phone;
                    s.detail_user.address= f.address;
                    s.detail_user.city= f.city;
                    s.detail_user.country= f.country;
                    s.detail_user.first_name= f.first_name;
                    s.detail_user.last_name= f.last_name;
                    s.detail_user.gender= f.gender;
                    s.detail_user.state = f.state;
                    s.detail_user.province = f.province;
                    s.detail_user.image = imageUrl;
                    dispatch(UPDATE_USER(JSON.stringify(s)));
                } else {
                    var usr = res.data.detail_user;
                    form.setFieldsValue({
                        username: res.data.username,
                        email: res.data.email,
                        phone: res.data.phone,
                        address: usr.address,
                        city: usr.city,
                        country: usr.country,
                        first_name: usr.first_name,
                        last_name: usr.last_name,
                        gender: usr.gender,
                        state: usr.state,
                        province:usr.province
                    })
                    setUser(res.data);
                    setImageUrl(usr.image);
                }
            } else {
                modalError(res.message);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const uploadImage = (info) => {
        setFile(info.file);
        getBase64(info.file, (url) => {
            setImageUrl(url);
        });
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    return (
        <div>

            <Row>
                <Col span={12}>
                    <b>Profile</b>
                </Col>
                <Col span={12}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                        <Button type="primary" onClick={form.submit} >
                            <b style={{ marginLeft: 5 }}>Simpan</b>
                        </Button>
                    </div>
                </Col>
            </Row>
            <Form form={form} name="form" layout="vertical" onFinish={onFinish}>
                <Row>
                    <Col span={14}>
                        <Row>
                            <Col span={11}>
                                <Form.Item
                                    name="first_name"
                                    label="Nama Depan"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Nama Depan kosong!'
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={2} />
                            <Col span={11}>
                                <Form.Item
                                    name="last_name"
                                    label="Nama Belakang"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Nama Belakang kosong!'
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item
                            name="username"
                            label="Username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Username kosong!'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            label="E-mail"
                            rules={[
                                {
                                    required: true,
                                    message: 'E-mail kosong!'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="phone"
                            label="Nomor HP"
                            rules={[
                                {
                                    required: true,
                                    message: 'Nomor HP kosong!'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="gender"
                            label="Pilih Jenis Kelamin"
                            rules={[
                                {
                                    required: false,
                                    message: 'Pilih Jenis Kelamin kosong!'
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Pilih Jenis Kelamin"
                                // optionFilterProp="children"
                                // onChange={onChange}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={[
                                    {
                                        value: 'men',
                                        label: 'Laki - Laki',
                                    },
                                    {
                                        value: 'woman',
                                        label: 'Perempuan',
                                    },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            name="address"
                            label="Alamat"
                            rules={[
                                {
                                    required: false,
                                    message: 'Alamat kosong!'
                                },
                            ]}
                        >
                            <Input.TextArea />
                        </Form.Item>
                        <Row>
                            <Col span={7}>
                                <Form.Item
                                    name="city"
                                    label="Kota"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Kota kosong!'
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={1} />
                            <Col span={7}>
                                <Form.Item
                                    name="province"
                                    label="Provinsi"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Provinsi kosong!'
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={1} />
                            <Col span={7}>
                                <Form.Item
                                    name="country"
                                    label="Negara"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Negara kosong!'
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={2} />
                    <Col span={8} >
                        <Upload
                            listType="picture-card"
                            showUploadList={false}
                            onChange={uploadImage}
                            beforeUpload={() => false}
                        >
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{ height: 140, width:140,objectFit: 'contain'}}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                        Upload Gambar
                    </Col>
                </Row>
            </Form>
        </div>
    );

}

export default Profile;