import secureLocalStorage from 'react-secure-storage';

export function setAuth({ user, menu, token }) {
    localStorage.setItem('user', user);
    localStorage.setItem('menu', menu);
    localStorage.setItem('token', token);
}

export function getToken() {
    return localStorage.getItem('token');
}

export function getUser() {
    return localStorage.getItem('user');
}

export function setUser(user) {
    return localStorage.setItem('user', user);
}

export function getMenu() {
    return localStorage.getItem('menu');
}

export function clearAllData() {
    secureLocalStorage.clear();
}

