// ServerConnectionChecker.js
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { notification } from 'antd';
import { API_URL } from '../../API/RestApi';

const ServerConnectionChecker = () => {
  const [isConnectedToServer, setIsConnectedToServer] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const notificationRef = useRef(null);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      if (notificationRef.current !== 'online') {
        notification.success({
          message: 'Online',
          description: 'You are back online!',
          placement: 'topRight',
        });
        notificationRef.current = 'online';
      }
    };

    const handleOffline = () => {
      setIsOnline(false);
      if (notificationRef.current !== 'offline') {
        notification.error({
          message: 'Offline',
          description: 'You are offline. Please check your internet connection.',
          placement: 'topRight',
        });
        notificationRef.current = 'offline';
      }
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    const checkServerConnection = async () => {
      const start = new Date().getTime();
      try {
        const response = await axios.get(API_URL+'/check', { timeout: 5000 });
        const end = new Date().getTime();
        const duration = end - start;

        if (duration > 3000) { // Threshold for slow connection
          if (notificationRef.current !== 'slow') {
            notification.warning({
              message: 'Slow Connection',
              description: 'Server is connected but the response is slow.',
              placement: 'topRight',
            });
            notificationRef.current = 'slow';
          }
        } else {
          if (isConnectedToServer !== true && notificationRef.current !== 'connected') {
            notification.success({
              message: 'Connected',
              description: 'Server is connected!',
              placement: 'topRight',
            });
            notificationRef.current = 'connected';
          }
          setIsConnectedToServer(true);
        }
      } catch (error) {
        if (isConnectedToServer !== false && notificationRef.current !== 'disconnected') {
          notification.error({
            message: 'Disconnected',
            description: 'There was an error connecting to the server!',
            placement: 'topRight',
          });
          notificationRef.current = 'disconnected';
        }
        setIsConnectedToServer(false);
      }
    };

    if (isOnline) {
      checkServerConnection(); // Initial check
      const interval = setInterval(checkServerConnection, 5000); // Check every 5 seconds
      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [isOnline, isConnectedToServer]);

  return null; // This component does not render anything visible
};

export default ServerConnectionChecker;
